import { ArchiveOutlined, DeleteOutlined } from "@mui/icons-material";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

export function ActionsEditMode({ params, disabled }) {
  const apiRef = useGridApiContext();

  const handleDeleteRow = (e) => {
    apiRef.current.updateRows([{ id: params?.id, _action: "delete" }]);
  };

  return (
    params?.row?.newHost && (
      <>
        <Tooltip title="Excluir">
          <IconButton disabled={disabled} onClick={handleDeleteRow}>
            <DeleteOutlined fontSize="small" color="error" />
          </IconButton>
        </Tooltip>
      </>
    )
  );
}
export function Actions({ params, disabled, updateStateHost }) {
  return (
    <>
      <Tooltip title="Arquivar">
        <IconButton
          disabled={disabled}
          onClick={() => updateStateHost(params.id)}
        >
          <ArchiveOutlined fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );
}
