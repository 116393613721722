import {
  Paper,
  Stack,
  TextField,
  Divider,
  Fade,
  LinearProgress,
  Typography,
  useTheme,
  Button,
  Autocomplete,
  Drawer,
  Box,
  Tooltip,
  IconButton,
  Skeleton,
  Chip,
  CircularProgress,
  InputAdornment,
} from "@mui/material";

import React, {
  Fragment,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from "react";
//icons
import TextFieldsIcon from "@mui/icons-material/TextFields";
import EditLocationAltOutlinedIcon from "@mui/icons-material/EditLocationAltOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import { ReactComponent as LinkedIcon } from "../../../assets/easyMaps/iconsSvg/Linked.svg";
import { ReactComponent as InfoIcon } from "../../../assets/easyMaps/iconsSvg/info.svg";
import { ReactComponent as Produto } from "../../../assets/easyMaps/iconsSvg/produto.svg";
import { ReactComponent as ItemIcon } from "../../../assets/easyMaps/iconsSvg/item.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CardConnection from "./cardConnection";
import ModalIcon from "./modalAddIcons";
import ModalMap from "./modalAddMap";
import IconTransform from "../iconsElement";
import ListIcon from "./listIcons";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import NotListedLocationOutlinedIcon from "@mui/icons-material/NotListedLocationOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DeleteOutline } from "@mui/icons-material";

const CreationSliderBarEasyMaps = forwardRef(
  (
    {
      setOpenAlertModal = () => {},
      handleUpdatePositionElement = () => {},
      focusZoomCoordenates = () => {},
      iconsElement,
      setIconsElement,
      mapId,
      socket,
      element,
      connections = [],
      elements = [],
      setElements,
      setConnections,
      produtos = [],
      api,
      setSnackbar,
      setElementSelected,
      maps = [],
      addMapInMaps = () => {},
      fullScreem,
      tipoMapa = 0,
      coordenadasFlow,
      setFocusNodeId = () => {},
      checkChanges,
      setCheckChanges = () => {},
      updateDataElementSocket = () => {},
      deleteElementSocket = () => {},
      centerLeaflatMap = {},
    },
    ref
  ) => {
    const theme = useTheme();
    //variveis default
    const tiposElementos = ["Host", "Mapa", "POP"];
    //variaveis do elemento
    const [connectionsElement, setConnectionsElement] = useState([]);
    const [connectionsElementOld, setConnectionsElementOld] = useState([]);
    const [elementName, setElementName] = useState(null);
    const [typeElement, setTypeElement] = useState(null);
    const [productElement, setProductElement] = useState(null);
    const [iconElement, setIconElement] = useState(null);
    const [mapRefElement, setMapRefElement] = useState(null);
    const [coordinatesLat, setCoordinatesLat] = useState(null);
    const [coordinatesLon, setCoordinatesLon] = useState(null);
    //variaveis zabbix
    const [hostsCliente, setHostsCliente] = useState([]);
    const [itensConfig, setItensConfig] = useState([]);
    const [requestSend, setRequestSend] = useState(false);
    const [hostsConfigOld, setHostsConfigOld] = useState([]);
    const [itensHost, setItensHost] = useState([]);
    //outras variaveis
    const [openModalImg, setOpenModalImg] = useState(false);
    const [hostsConfig, setHostsConfig] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const [buttonHostDisable, setButtonHostDisable] = useState(true);
    const [hostLoading, setHostLoading] = useState(false);
    const [itensLoading, setItensLoading] = useState(false);
    const [openModalMap, setOpenModalMap] = useState(false);

    const styles = {
      buttonAdd: {
        width: "max-content",
        padding: "2px 10px",
        justifyContent: "flex-start",
        textTransform: "none",
        fontWeight: 400,
        mt: "-4px",
        mb: "-2px",

        color: theme.palette.mode == "dark" ? "#ff8282" : "none",
      },
      containerData: {
        overflow: "auto",
        height: "92vh",
        padding: "10px 10px",
        marginBottom: "10px",
      },
      text: {
        fontSize: "0.875rem",
        color: theme.palette.color.text,
      },
      paper: {
        borderRadius: "0px",
        height: "100vh",
        position: "sticky",
        width: "340px!important",
        backgroundColor: theme.palette.background.slideBar,
      },
      card: {
        backgroundColor: theme.palette.background.paperLinkedMaps,
        boxShadow: "0px 0px 5px  #0000001a",
        display: "flex",
        direction: "row",
        padding: "10px",

        width: "100%",
      },
    };

    useImperativeHandle(ref, () => ({
      update() {
        updateElement();
      },
    }));

    useEffect(() => {
      if (typeElement == "Host") return;
      setElementName(mapRefElement?.nome);
    }, [mapRefElement]);

    useEffect(() => {
      if (connections?.length == 0 || !element?.id) {
        setConnectionsElement([]);
        setConnectionsElementOld([]);
        return;
      }
      let con = connections?.filter((a) => a.referenceId == element.id);

      setConnectionsElement(con);
      setConnectionsElementOld(con);
    }, [connections, element]);

    useEffect(() => {
      if (!element) return;
      if (!element?.elementConfig?.config?.coordenadas && tipoMapa == 0) {
        handleUpdatePositionElement(null, centerLeaflatMap);
        setCoordinatesLat(centerLeaflatMap?.lat);
        setCoordinatesLon(centerLeaflatMap.lng);
      } else {
        setCoordinatesLat(element?.elementConfig?.config?.coordenadas?.lat);
        setCoordinatesLon(element?.elementConfig?.config?.coordenadas?.long);
      }
    }, [element]);

    useEffect(() => {
      setCheckChanges(false);

      if (!element) return;

      if (!element.id) {
        clean();
      }

      setTypeElement(tiposElementos[element?.tipo]);
      setIconElement(
        iconsElement?.find((icon) => icon.id == element.img) ||
          iconsElement?.find(
            (icon) =>
              icon.nome ==
              (element.tipo === 0
                ? "_roteador"
                : element.tipo === 1
                ? "_mapa"
                : "_pop")
          )
      );

      if (element.id) {
        setElementName(element?.titulo);
        if (element.mapRefId == null && element.tipo == 0) {
          setHostsConfig(element?.elementConfig?.config?.hosts);
          setItensConfig(element?.elementConfig?.config?.hosts[0]?.itens || []);
        } else {
          setHostsConfig(
            element?.mapRef?.Element?.flatMap(
              (ele) => ele.elementConfig?.config?.hosts
            )
          );
          setItensHost([]);
          setItensConfig([]);
        }
        setMapRefElement(maps?.find((map) => map.id == element?.mapRefId));

        setProductElement(
          produtos.find(
            (produto) => produto.id == element?.elementConfig?.config?.produtoId
          )
        );
        setCoordinatesLat(element?.elementConfig?.config?.coordenadas?.lat);
        setCoordinatesLon(element?.elementConfig?.config?.coordenadas?.long);
      } else {
        if (produtos?.length == 1) {
          setProductElement(produtos[0]);
          if (typeElement == "Host") {
            getHosts(produtos[0]?.AcessoLink[0]?.link);
          }
        } else {
          setProductElement(null);
        }
      }
    }, [element?.id, element?.tipo]);
    //fuções
    useEffect(() => {
      if (productElement == null) {
        setHostsCliente([]);
        setHostsConfig([]);
        setItensConfig([]);
        setButtonHostDisable(true);
      }
      if (
        productElement != null &&
        Array.isArray(productElement?.AcessoLink) &&
        productElement?.AcessoLink?.length > 0 &&
        productElement?.AcessoLink[0]?.link != undefined &&
        typeElement == "Host"
      ) {
        setButtonHostDisable(true);
        getHosts(productElement?.AcessoLink[0]?.link);
      }
    }, [productElement, typeElement]);

    useEffect(() => {
      if (hostsConfig?.length == 0) {
        setItensHost([]);
        setConnectionsElement((data) => {
          data?.map((conection) => {
            conection.item = [];
          });
          return [...data];
        });
      }
      //remove os items que não tem host seleciocionado
      setItensHost((data) => {
        let filter = data?.filter((item) => {
          return hostsConfig?.some((host) => {
            return host?.hostid == item?.hostid;
          });
        });
        return [...filter];
      });
      //adiciona itens de novos host selecionados
      hostsConfig?.map(async (host) => {
        getItens(host?.hostid);
      });

      setHostsConfigOld(hostsConfig?.map((host) => ({ hostid: host?.hostid })));
    }, [hostsConfig]);

    const validHost = useCallback(() => {
      return Array.isArray(hostsConfig) &&
        hostsConfig?.length > 0 &&
        hostsConfig[0]
        ? hostsConfig[0]
        : [];
    }, [hostsConfig]);

    async function getHosts(link) {
      if (!link) return;
      setHostLoading(true);
      try {
        const response = await api.post("/easyMaps/zabbix/hosts", {
          link: link,
        });
        if (response.data.status === "Error") {
          setSnackbar({
            children: "Error: Não foi possível buscar os hosts",
            severity: "error",
          });
        } else {
          setButtonHostDisable(false);
          setHostsCliente(response.data);
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setHostLoading(false);
      }
    }

    async function getItens(hostid) {
      if (hostsConfig?.length == 0) return;
      setItensLoading(true);
      try {
        const response = await api.post("/easyMaps/zabbix/itens", {
          link: productElement?.AcessoLink[0]?.link,
          hostids: hostid,
        });
        if (response.data.status === "Error") {
          setSnackbar({
            children: "Error: Não foi possível buscar os hosts",
            severity: "error",
          });
        } else {
          setItensHost((data) => [...data, ...response.data]);
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setItensLoading(false);
      }
    }

    async function handleApiGetElements(mapId) {
      setHostsConfig([]);
      setItensLoading(true);
      setHostLoading(true);
      try {
        //setLoadingProdutos(true);
        const response = await api.post("/easyMaps/element/findMany", {
          mapId,
        });
        if (response.data.status === "Error") {
          setSnackbar({
            children: "Error: Não foi possível buscar o mapa",
            severity: "error",
          });
        } else {
          const element = response.data.responseAlerts;

          setHostsConfig(
            element
              ?.filter((ele) => ele.tipo == 0)
              ?.flatMap((map) => map.elementConfig?.config?.hosts)
          );
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setHostLoading(false);
        setItensLoading(false);
      }
    }

    function createElement() {
      setRequestSend(true);
      if (!isValidate()) return;

      let logs = JSON.parse(localStorage.getItem("logs"));
      let data = {
        titulo: elementName,
        tipo: tiposElementos.indexOf(typeElement),
        img: iconElement.id,
        mapRefId: mapRefElement?.id || null,
        configuracao: {
          coordenadas:
            tipoMapa == 0
              ? {
                  lat: coordinatesLat || centerLeaflatMap?.lat,
                  long: coordinatesLon || centerLeaflatMap?.lng,
                }
              : {
                  lat: coordenadasFlow?.y
                    ? coordenadasFlow?.y + Math.floor(Math.random() * 21) - 10
                    : 0,
                  long: coordenadasFlow?.x
                    ? coordenadasFlow?.x + Math.floor(Math.random() * 21) - 10
                    : 0,
                },
          hosts:
            typeElement === "Host"
              ? [{ ...validHost(), itens: itensConfig }]
              : hostsConfig,
          produtoId: productElement?.id,
        },
        mapId: mapId,
        usuarioClienteId: logs.usuarioClienteId,
        logsLoginClienteId: logs.id,
        connections: connectionsElement?.filter(
          (con) => con.destinationId != "" && con.destinationId != null
        ),
      };

      try {
        socket.emit("createElement", data, (response) => {
          if (response.status !== 201) {
            setSnackbar({
              children: "Error: Não foi possível criar o elemento",
              severity: "error",
            });
          } else {
            updateDataElementSocket();
            setSnackbar({
              children: "Elemento criado com sucesso!",
              severity: "success",
            });
            closeDrawer();

            let newElement = response.data.element;
            let newLinks = response.data.element.connections;
            setElements((data) => [newElement, ...data]);

            if (newLinks) setConnections((data) => [...newLinks, ...data]);
            setFocusNodeId(response.data.element?.id);
            let cood = newElement.elementConfig.config.coordenadas;
            focusZoomCoordenates(cood.lat, cood.long);
          }
        });
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setCheckChanges(false);
      }
    }

    async function updateElement() {
      setRequestSend(true);

      if (!isValidate()) return;

      //verificar o que foi alterado nas conexões
      let arrayDelete = [];
      //verifica se houve delete
      connectionsElementOld?.map((connectionOld) => {
        if (!connectionsElement?.some((con) => con.id == connectionOld.id)) {
          arrayDelete.push(connectionOld.id);
        }
      });

      // atualização do elemento puro
      let logs = JSON.parse(localStorage.getItem("logs"));
      let data = {
        id: element.id,
        titulo: elementName,
        mapRefId: mapRefElement?.id || null,
        tipo: tiposElementos.indexOf(typeElement),
        img: iconElement.id,
        configuracao: {
          coordenadas:
            tipoMapa == 0
              ? {
                  lat:
                    coordinatesLat == "" || !coordinatesLat
                      ? centerLeaflatMap?.lat
                      : coordinatesLat,
                  long:
                    coordinatesLon == "" || !coordinatesLon
                      ? centerLeaflatMap?.lng
                      : coordinatesLon,
                }
              : {
                  lat: coordinatesLat,
                  long: coordinatesLon,
                },
          hosts:
            typeElement === "Host"
              ? [{ ...validHost(), itens: itensConfig }]
              : hostsConfig,
          produtoId: productElement?.id,
        },
        connections: {
          updates: connectionsElement?.filter(
            (con) => con.destinationId != "" && con.destinationId != null
          ),
          deletes: arrayDelete,
        },
        usuarioClienteId: logs.usuarioClienteId,
        logsLoginClienteId: logs.id,
      };
      try {
        socket.emit("updateElement", data, (response) => {
          if (response.status !== 201) {
            setSnackbar({
              children: "Error: Não foi possível atualizar o elemento",
              severity: "error",
            });
          } else {
            updateDataElementSocket();
            setSnackbar({
              children: "Elemento atualizado com sucesso!",
              severity: "success",
            });
            setRequestSend(false);

            let updateElement = response.data.element;
            let updatedLinks = response.data.updatedLinks;

            setElements((elements) => {
              return elements.map((ele) =>
                ele.id === updateElement.id
                  ? { ...updateElement, alerts: ele.alerts }
                  : ele
              );
            });

            setConnections((con) => {
              con = con?.filter((c) => c.referenceId !== element.id);
              return [
                ...updatedLinks?.filter((link) => link?.id != null),
                ...con,
              ];
            });
          }
        });
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setCheckChanges(false);
      }
    }

    function addConexao() {
      setConnectionsElement((data) => [...data, {}]);
      setExpanded(connectionsElement?.length);
    }

    function removeConexao(id) {
      setConnectionsElement((data) => {
        return [...data?.filter((con) => con.id !== id)];
      });
    }

    function updateConexao(index, host) {
      setConnectionsElement((data) => {
        data[index] = host;
        return [...data];
      });
    }
    function clean() {
      setRequestSend(false);
      setConnectionsElement([]);
      setElementName(null);
      setHostsConfig([]);
      setItensConfig([]);
      setProductElement(null);
      setMapRefElement(null);
      setExpanded(null);
      setMapRefElement(null);
      setIconElement(null);
      setCoordinatesLon(null);
      setCoordinatesLat(null);
    }
    function closeDrawer() {
      setElementSelected(null);
      clean();
    }

    const isOptionEqualToValue = (option, value) => {
      return option?.hostid === value?.hostid;
    };
    const isOptionEqualToValueNome = (option, value) => {
      return option?.nome === value?.nome;
    };

    const isValidate = () => {
      if (typeElement === "Mapa" || typeElement === "POP") {
        return (
          mapRefElement != null &&
          elementName != "" &&
          elementName != null &&
          typeElement != null &&
          productElement != null &&
          iconElement != null
        );
      } else {
        return (
          elementName != "" &&
          elementName != null &&
          hostsConfig?.length != 0 &&
          hostsConfig[0] != null &&
          typeElement != null &&
          productElement != null &&
          iconElement != null
        );
      }
    };
    const mapsFilter = (maps, tipo) => {
      return tipo == "POP"
        ? maps?.filter(({ tipo, id }) => tipo == 2 && id != mapId)
        : maps?.filter(({ tipo, id }) => tipo != 2 && id != mapId);
    };
    const selectMapRef = (map) => {
      const newValue = { nome: map?.titulo, tipo: map?.tipo, id: map?.id };
      setMapRefElement(newValue);
      setElementName(newValue?.nome);
      if (newValue) handleApiGetElements(newValue.id);
      else setHostsConfig([]);
    };
    useEffect(() => {
      if (element === null) {
        clean();
      }
    }, [element]);

    const handleOnDeleteIcon = (id) => {
      setOpenAlertModal({
        open: true,
        severity: "error",
        text: "Essa ação apagará o icon do banco de forma irreversível.\n Deseja continuar?",
        callback: async () => {
          try {
            const response = await api.put("easyMaps/img/delete", { id });
            if (response.data.status === "Error") {
              setSnackbar({
                children: "Error: não foi possível deletar o icon",
                severity: "error",
              });
            } else {
              setIconsElement((icons) => {
                let newicons = icons.filter((a) => a.id != id);
                return [...newicons];
              });
              setSnackbar({
                children: "Icone excluido com sucesso",
                severity: "success",
              });
            }
          } catch (error) {
            console.log(error);
            setSnackbar({
              children: "Error: Não foi possivel se conectar com o servidor",
              severity: "error",
            });
          }
        },
      });
    };
    return (
      <>
        <Drawer
          key={"creationBar"}
          variant="persistent"
          anchor={"right"}
          open={!!element}
          //onClose={() => closeDrawer()}
          sx={{
            position: "absolute",
            clipPath: "none",
            marginTop: "65px",
            flexShrink: 0,
            backgroundColor: theme.palette.background.slideBar,
            "& .MuiDrawer-paper": {
              marginTop: fullScreem ? "" : "65px",
              overflow: "visible",
              backgroundColor: theme.palette.background.slideBar,
              borderLeft: "1px solid " + theme.palette.color.appBarBorder,
            },
          }}
        >
          <Paper elevation={0} sx={styles.paper}>
            <Stack direction="column" gap={1} sx={styles.containerData}>
              <Paper sx={[styles.card]}>
                <Stack
                  width="100%"
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <IconTransform img={iconElement?.img} size="35px" />
                  <Tooltip title={elementName}>
                    <Typography textAlign={"center"}>
                      {elementName?.length > 30
                        ? elementName.substring(0, 30) + "..."
                        : elementName}
                    </Typography>
                  </Tooltip>
                </Stack>
              </Paper>
              <Divider />
              <Stack
                gap={2}
                sx={{
                  maxHeight: "80vh",
                  overflow: "auto",
                  paddingRight: "10px",
                }}
              >
                <Stack
                  direction="row"
                  gap={2}
                  alignItems="center"
                  padding="5px 0px"
                >
                  <Produto
                    style={{
                      fill: theme.palette.primary.main,
                      height: "24px",
                      width: "24px",
                    }}
                  />
                  <Typography sx={styles.text}>
                    Selecione o produto de origem
                  </Typography>
                </Stack>
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) => option?.nome}
                  disabled={produtos?.length == 0}
                  value={productElement || null}
                  onChange={(event, newValue) => {
                    setCheckChanges(true);
                    setProductElement(newValue);
                  }}
                  id="combo-box-demo"
                  size="small"
                  color="primary"
                  options={produtos || []}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        error={requestSend && productElement == null}
                        helperText={
                          requestSend &&
                          productElement == null &&
                          "O produto precisa ser indicado!"
                        }
                        color="primary"
                        label="Produto"
                      />
                    </>
                  )}
                />
                <Stack direction="row" gap={2} alignItems="center">
                  <SettingsOutlinedIcon color="primary" />
                  <Typography sx={styles.text}>Selecione o tipo</Typography>
                </Stack>
                <Autocomplete
                  disablePortal
                  value={typeElement || null}
                  onChange={(event, newValue) => {
                    setCheckChanges(true);
                    setMapRefElement(null);
                    setHostsConfig([]);
                    setTypeElement(newValue);
                  }}
                  id="combo-box-demo"
                  size="small"
                  color="primary"
                  options={tiposElementos}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={requestSend && typeElement == null}
                      helperText={
                        requestSend &&
                        typeElement == null &&
                        "O tipo não pode ser nulo!"
                      }
                      color="primary"
                      label="Tipo de elemento"
                    />
                  )}
                />
                {typeElement == "Host" ? (
                  <>
                    <Stack direction="row" gap={2} alignItems="center">
                      <>
                        <StorageOutlinedIcon color="primary" />
                        <Typography sx={styles.text}>
                          Selecione o host
                        </Typography>
                      </>
                    </Stack>

                    <Autocomplete
                      disablePortal
                      disabled={buttonHostDisable}
                      getOptionLabel={(option) => option.host}
                      isOptionEqualToValue={isOptionEqualToValue}
                      id="combo-box-demo"
                      size="small"
                      color="primary"
                      options={hostsCliente}
                      value={
                        Array.isArray(hostsConfig) && hostsConfig.length > 0
                          ? hostsConfig[0]
                          : null
                      }
                      onChange={(event, newValue) => {
                        setCheckChanges(true);
                        //setItensLoading(true);
                        if (newValue == null) setHostsConfig([]);
                        else
                          setHostsConfig([
                            { ...newValue, hostname: newValue.host },
                          ]);
                        if (newValue?.hostid !== validHost()?.hostid)
                          setItensConfig([]);
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            error={
                              requestSend &&
                              (hostsConfig?.length == 0 ||
                                hostsConfig[0] == null)
                            }
                            helperText={
                              requestSend &&
                              (hostsConfig?.length == 0 ||
                                hostsConfig[0] == null) &&
                              "O host não pode ser nulo!"
                            }
                            color="primary"
                            label="Host"
                          />
                          <Fade in={hostLoading}>
                            <LinearProgress sx={{ height: "2px" }} />
                          </Fade>
                        </>
                      )}
                    />
                    <Stack direction="row" gap={2} alignItems="center">
                      <>
                        {/* <StorageOutlinedIcon color="primary" /> */}
                        <ItemIcon
                          style={{
                            fill: theme.palette.primary.main,
                            height: "24px",
                            width: "24px",
                          }}
                        />
                        <Typography sx={styles.text}>
                          Selecione os itens do host
                        </Typography>
                      </>
                    </Stack>

                    <Autocomplete
                      multiple
                      key="itensAutocomplete"
                      disableCloseOnSelect
                      //sx={{ width: "245px" }}
                      disabled={typeElement != "Host" && validHost() == null}
                      //disablePortal
                      id="itensHost"
                      size="small"
                      color="primary"
                      options={
                        typeElement == "Host"
                          ? itensHost
                          : itensHost?.filter(
                              (item) => item?.hostid === validHost()?.hostid
                            )
                      }
                      value={itensConfig}
                      onChange={(event, newValue) => {
                        setItensConfig(newValue);
                      }}
                      renderTags={(value, getTagProps) => {
                        return value?.map((option, index) => (
                          <Tooltip
                            title={option.name}
                            key={option.itemid + "toolItens"}
                          >
                            <Chip
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          </Tooltip>
                        ));
                      }}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) =>
                        option.itemid === value.itemid
                      }
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          key={option.itemid + option.name + "itens"}
                        >
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <>
                          {" "}
                          <TextField
                            {...params}
                            key="itensAutoText"
                            color="primary"
                            label="Itens"
                            helperText={
                              typeElement == "Host" && !itensLoading
                                ? itensHost?.length == 0 &&
                                  "Esse elemento não contém itens"
                                : itensHost?.filter(
                                    (item) =>
                                      item.hostid === validHost()?.hostid
                                  )?.length == 0 &&
                                  validHost() != null &&
                                  !itensLoading &&
                                  "O host selecionado não contém itens"
                            }
                          />{" "}
                          <Fade in={itensLoading}>
                            <LinearProgress sx={{ height: "2px" }} />
                          </Fade>
                        </>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Stack direction={"column"} gap={1} alignItems={"start"}>
                      <Stack
                        direction="row"
                        gap={2}
                        alignItems="center"
                        width="100%"
                        mb={"5px"}
                      >
                        <MapOutlinedIcon color="primary" />
                        <Typography sx={styles.text}>
                          Selecione o mapa de referência
                        </Typography>
                      </Stack>

                      <Autocomplete
                        disablePortal
                        sx={{ width: "100%" }}
                        value={mapRefElement || null}
                        getOptionLabel={(option) => option?.nome || ""}
                        isOptionEqualToValue={isOptionEqualToValueNome}
                        onChange={(event, newValue) => {
                          setCheckChanges(true);

                          setMapRefElement(newValue);
                          setElementName(newValue?.nome);
                          if (newValue) handleApiGetElements(newValue.id);
                          else setHostsConfig([]);
                        }}
                        id="combo-box-demo"
                        size="small"
                        color="primary"
                        options={
                          Array.isArray(maps)
                            ? mapsFilter(maps, typeElement)
                            : []
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={requestSend && mapRefElement == null}
                            helperText={
                              requestSend &&
                              mapRefElement == null &&
                              "A referência do mapa não pode ser nula!"
                            }
                            color="primary"
                            label="Mapa de referência"
                          />
                        )}
                      />
                      <Button
                        startIcon={<AddOutlinedIcon />}
                        variant="text"
                        onClick={() => setOpenModalMap(true)}
                        sx={styles.buttonAdd}
                      >
                        Criar novo mapa
                      </Button>
                      {/* <Divider sx={{ width: "100%", mt: 1 }} /> */}
                    </Stack>
                  </>
                )}
                <Stack direction={"column"} gap={1} alignItems={"start"}>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    width="100%"
                    mb={"5px"}
                  >
                    <NotListedLocationOutlinedIcon color="primary" />
                    <Typography sx={styles.text}>Selecione o ícone</Typography>
                    <Tooltip sx={{ ml: "-5px" }} title="Admintração de icons">
                      <IconButton size="small">
                        <InfoIcon
                          style={{
                            fill: theme.palette.color.icons,
                            height: "19px",
                            width: "19px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>

                  <Autocomplete
                    options={iconsElement.sort((a, b) => {
                      const groupA = a.nome.startsWith("_") ? 0 : 1;
                      const groupB = b.nome.startsWith("_") ? 0 : 1;
                      return groupA - groupB;
                    })}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.nome}
                    groupBy={(option) =>
                      option.nome.startsWith("_")
                        ? "Icons Default"
                        : "Icons Personalizados"
                    }
                    value={iconElement || null}
                    onChange={(event, newValue) => {
                      setCheckChanges(true);
                      setIconElement(newValue);
                    }}
                    id="combo-box-demo"
                    size="small"
                    color="primary"
                    renderOption={(props, option) => (
                      <ListIcon
                        props={props}
                        key={option?.id}
                        option={option}
                        handleOnDelete={(id) => handleOnDeleteIcon(id)}
                      />
                    )}
                    renderInput={(params) => {
                      if (params.inputProps.value.startsWith("_"))
                        params.inputProps.value =
                          params.inputProps.value.replace("_", "");
                      return (
                        <TextField
                          {...params}
                          slotProps={{
                            input: {
                              ...params.InputProps,

                              startAdornment: (
                                <Stack ml={1} alignItems="center">
                                  <IconTransform
                                    img={iconElement?.img}
                                    size="22px"
                                  />
                                </Stack>
                              ),
                            },
                          }}
                          color="primary"
                          error={requestSend && iconElement == null}
                          helperText={
                            requestSend &&
                            iconElement == null &&
                            "Informe o ícone do elemento!"
                          }
                          label="Ícone"
                        />
                      );
                    }}
                  />
                  <Button
                    startIcon={<AddOutlinedIcon />}
                    variant="text"
                    onClick={() => setOpenModalImg(true)}
                    sx={styles.buttonAdd}
                  >
                    Criar novo ícone
                  </Button>
                  {/* <Divider sx={{ width: "100%", mt: 1 }} /> */}
                </Stack>
                {typeElement == "Host" && (
                  <>
                    <Stack direction="row" gap={2} alignItems="center">
                      <TextFieldsIcon color="primary" />
                      <Typography sx={styles.text}>
                        Informe o nome do elemento
                      </Typography>
                    </Stack>
                    <TextField
                      autoComplete="off"
                      value={elementName || ""}
                      onChange={(value) => {
                        setCheckChanges(true);
                        setElementName(value.target.value);
                      }}
                      color="primary"
                      id="hostName"
                      label="Nome do Elemento"
                      variant="outlined"
                      size="small"
                      error={
                        requestSend &&
                        (elementName == "" || elementName == null)
                      }
                      helperText={
                        requestSend &&
                        (elementName == "" || elementName == null) &&
                        "O nome não pode ser nulo!"
                      }
                    />
                  </>
                )}
                {tipoMapa == 0 && (
                  <>
                    <Stack direction="row" gap={2} alignItems="center">
                      <EditLocationAltOutlinedIcon color="primary" />
                      <Typography sx={styles.text}>
                        Informe as coordenadas
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <TextField
                        autoComplete="off"
                        value={coordinatesLat || ""}
                        onChange={(value) => {
                          setCheckChanges(true);
                          setCoordinatesLat(value.target.value);
                        }}
                        color="primary"
                        id="hostName"
                        label="Latitude"
                        variant="outlined"
                        size="small"
                        type="number"
                      />

                      <TextField
                        autoComplete="off"
                        value={coordinatesLon || ""}
                        onChange={(value) => {
                          setCheckChanges(true);
                          setCoordinatesLon(value.target.value);
                        }}
                        color="primary"
                        id="hostName"
                        label="Longitude"
                        variant="outlined"
                        size="small"
                        type="number"
                      />
                    </Stack>
                  </>
                )}
                {/* <Stack direction="row" gap={2} alignItems="center">
                <LinkedIcon
                  style={{
                    fill: theme.palette.primary.main,
                    height: "24px",
                    width: "24px",
                  }}
                />
                <Typography sx={styles.text}>
                  Conexão(ões) de entrada
                </Typography>
              </Stack> */}
                <Stack direction="row" gap={2} alignItems="center">
                  <LinkedIcon
                    style={{
                      fill: theme.palette.primary.main,
                      height: "24px",
                      width: "24px",
                    }}
                  />
                  <Typography sx={styles.text}>
                    Selecione a(s) conexão(ões)
                  </Typography>
                </Stack>
                {itensLoading && hostsConfig.length > 0 && (
                  <Skeleton variant="rounded" width="100%" height={50} />
                )}
                {!itensLoading && connectionsElement?.length > 0 && (
                  <Stack gap={1}>
                    {connectionsElement?.map((connection, index) => (
                      <CardConnection
                        key={`connection-${connection?.id}-${connectionsElement?.length}-${element?.id}`}
                        idElement={element?.id}
                        hostsConfig={hostsConfig}
                        typeElement={typeElement}
                        id={connection.id}
                        productId={productElement?.id}
                        index={index}
                        connection={connection}
                        elements={elements}
                        itens={itensHost}
                        updateConexao={updateConexao}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        removeConexao={removeConexao}
                        coordinatesLat={coordinatesLat}
                        coordinatesLon={coordinatesLon}
                      />
                    ))}
                  </Stack>
                )}
                <Tooltip
                  title={
                    (hostsConfig?.length == 0 &&
                      "Nenhum host foi selecionado") ||
                    (itensHost?.length == 0 &&
                      "O host selecionado não contém itens de tráfico")
                  }
                  disableHoverListener={
                    !itensHost?.length == 0 || !hostsConfig?.length == 0
                  }
                >
                  <span>
                    <Button
                      disabled={itensHost?.length == 0}
                      sx={{
                        width: "150px",
                        color: theme.palette.color.text,
                      }}
                      color="primary"
                      variant="text"
                      onClick={addConexao}
                      startIcon={<AddOutlinedIcon />}
                    >
                      Nova conexão
                    </Button>
                  </span>
                </Tooltip>
              </Stack>{" "}
              <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
                <Button
                  disabled={itensLoading || !isValidate()}
                  sx={{ width: "50%", mt: "10px" }}
                  color="primary"
                  variant="contained"
                  endIcon={<SaveOutlinedIcon />}
                  onClick={() => {
                    element?.id ? updateElement() : createElement();
                    closeDrawer();
                  }}
                >
                  {element?.id ? "Atualizar" : "Adicionar"}
                </Button>
                <Button
                  sx={{ width: "50%", mt: "10px" }}
                  color="primary"
                  variant="outlined"
                  endIcon={<HighlightOffIcon />}
                  onClick={() =>
                    checkChanges
                      ? setOpenAlertModal({
                          open: true,
                          severity: "warning",
                          text: "As alterações feitas não foram salvas. Se prosseguir com a operação as mudanças serão perdidas.",
                        })
                      : closeDrawer()
                  }
                >
                  {"Cancelar"}
                </Button>
              </Stack>{" "}
              {element?.id && (
                <Stack gap={2}>
                  <Button
                    sx={{
                      width: "100%",
                      //mt: "10px",
                      textDecoration: "none",
                    }}
                    color="primary"
                    variant="text"
                    //endIcon={<DeleteOutline />}
                    startIcon={<DeleteOutline />}
                    onClick={() => {
                      deleteElementSocket(element.id);
                    }}
                  >
                    {"Apagar Elemento"}
                  </Button>
                </Stack>
              )}
            </Stack>
          </Paper>
        </Drawer>
        <ModalIcon
          iconsElementNames={iconsElement.map((icon) => icon.nome)}
          openModalImg={openModalImg}
          setIconsElement={setIconsElement}
          setOpenModalImg={setOpenModalImg}
          api={api}
          setSnackbar={setSnackbar}
          setIconElement={setIconElement}
        />
        <ModalMap
          setOpen={setOpenModalMap}
          open={openModalMap}
          type={typeElement}
          api={api}
          addMapInMaps={addMapInMaps}
          selectMapRef={selectMapRef}
          setSnackbar={setSnackbar}
        />
      </>
    );
  }
);

export default CreationSliderBarEasyMaps;
