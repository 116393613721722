import { useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  Dialog,
  Alert,
  AlertTitle,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import api from "../../../services/api";
import _ from "lodash";
import { validateIp } from "../../../utils/validateIp";
import EditcellTextField from "../../../componentes/easyAdd/editCellTextField";
import EditCellAutoComplete from "../../../componentes/easyAdd/editCellAutoComplete";
import { Actions, ActionsEditMode } from "./actionCell";
import { CellExibition } from "./exibitionCell";
import {
  RenderEditCellWithMemo,
  RenderFuncaoEditCellWithMemo,
  RenderModeloEditCellWithMemo,
} from "./memos";
import EditCellAutoCompleteInterface from "../../../componentes/easyAdd/editCellAutoCompleteInterface/editCellAutoCompleteInterface";
import { DataGridWithNavigation } from "../../../componentes/easyAdd/dataGridNavigation";

export default function HostsPreCadastros(props) {
  const {
    predefinicoes,
    setSnackbar,
    hosts,
    setHosts,
    hostsZabbix,
    loading,
    proxys,
  } = props;
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [hostsEdit, setHostsEdit] = useState([]);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  //const [validateData, setValidateData] = useState(true);
  const [isEdit, setIsEdit] = useState([]);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertContent, setAlertContent] = useState("");
  const [cellModesModel, setCellModesModel] = useState({});
  const [cellSelected, setCellSelected] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    nome: true,
    ip: true,
    fabricante: true,
    funcao: true,
    modelo: true,
    coletor: true,
    interface: true,
    tipoSnmp: true,
    portaSnmp: true,
    comunidade: true,
    userTelnet: false,
    portaTelnet: false,
    senhaTelnet: false,
    userSsh: false,
    portaSsh: false,
    senhaSsh: false,
  });
  const theme = useTheme();
  const hostsEditRef = useRef();
  const isEditRef = useRef();

  useEffect(() => {
    hostsEditRef.current = hostsEdit;
    isEditRef.current = isEdit;
  }, [hostsEdit, isEdit]);

  const stylesPrecadastro = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "30px",
      width: "100%",
    },
    paper: {
      width: "100%",
      minWidth: "900px",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      borderRadius: 3,
      elevation: 3,
      gap: "20px",
    },
    accordion: {
      width: "100%",
      // width: "84vw",
      // minWidth: "900px",
      borderRadius: 3,
    },
    button: { height: "40px", minWidth: "120px", m: 1 },
    filter: { width: "180px" },
    deleButton: { width: "100px", fontWeight: "800" },
    dangerousIcon: { fontSize: 60 },
    boxDialogs: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
    },
    dialogText: { color: "#D32F2F" },
    porta: { width: "105px" },
    textField: { width: "230px" },
    titles: { fontWeight: 500, fontSize: "20px" },
    dataGrid: {
      height: "100%",
      fontSize: 13,
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
      ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
      height: "800px",
      width: "100%",

      "& .Mui-error": {
        backgroundColor: `rgb(126,10,15, ${
          theme.palette.mode === "dark" ? 0 : 0.1
        })`,
        color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
      },
      "& .MuiDataGrid-row": {
        backgroundColor: editMode ? theme.palette.background.paper : null,
      },
      "& .MuiDataGrid-cell": {
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          right: 0,
          top: "25%", // 10% da parte superior para centralizar a borda
          height: "50%", // 80% da altura da célula
          width: "1px",
          backgroundColor: theme.palette.color.textDisable, // Cor da borda
        },
      },
    },
  };

  async function handleApiUpdateHosts(hosts) {
    try {
      setLoadingUpdate(true);
      const response = await api.put("/easyAdd/hosts/update", {
        hosts,
      });
      if (response.data) {
        const updateHosts = response?.data;

        setHosts((prevHosts) => {
          const hostMap = new Map(prevHosts.map((host) => [host.id, host]));
          updateHosts?.forEach((newHost) => {
            hostMap.set(newHost.id, newHost);
          });
          return Array.from(hostMap.values());
        });
        setEditMode(false);
        setSnackbar({
          children: "As modificações foram salvas com sucesso!",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingUpdate(false);
      setHostsEdit([]);
      // setValidateData(true);
    }
  }

  async function updateStateHost(id) {
    handleApiUpdateHosts([
      {
        ...hosts.find((a) => a.id === id),
        status: 1,
      },
    ]);
  }

  async function updateHosts() {
    const interval = setInterval(() => {
      if (isEditRef.current.length > 0) return;
      hostsEditRef.current = hostsEditRef.current?.filter(
        (host) => !camposNulos(host)
      );
      if (!handleValidateData(hostsEditRef.current)) {
        clearInterval(interval);
        return;
      }
      handleApiUpdateHosts(hostsEditRef?.current);
      clearInterval(interval);
      return;
    }, 10);
  }

  const validaColunaNome = useCallback(
    (params) => {
      let handleError = params.error
        ? params.error
        : !params?.value
        ? "Campo obrigatório"
        : null;
      if (editMode && params?.value) {
        let comparaNomes = hosts?.some(
          ({ id, nome }) => params.value === nome && params.id !== id
        );
        if (hostsEdit.length > 0 && !comparaNomes)
          comparaNomes = hostsEdit?.some(
            ({ id, nome }) => params.value === nome && params.id !== id
          );
        const comparaNomesCadastrados = hostsZabbix?.some(
          ({ id, nome }) => params.value === nome && params.id !== id
        );
        if (comparaNomes) handleError = "Nome do host não pode ser repetido";
        if (comparaNomesCadastrados)
          handleError = "Já existe um host com esse nome cadastrado";
      }
      return handleError;
    },
    [hosts, hostsZabbix, hostsEdit, editMode]
  );

  const validaColunaIp = useCallback(
    (params) => {
      let errorMessage = !params?.value
        ? "Campo obrigatório"
        : validateIp(params?.value?.toString())
        ? null
        : "Formato de IP inválido";
      return errorMessage;
    },
    [editMode]
  );

  const columns = [
    { field: "id", headerName: "ID", width: 180, hide: true },
    {
      field: "nome",
      headerName: "NOME",
      width: 340,
      flex: 1,
      //width: 175,
      editable: true,
      renderCell: (params) => {
        return (
          <CellExibition
            {...params}
            error={validaColunaNome(params)}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            error={validaColunaNome(params)}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "ip",
      headerName: "IP",
      width: 200,
      editable: true,
      renderCell: (params) => {
        return (
          <CellExibition
            {...params}
            error={validaColunaIp(params)}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            error={validaColunaIp(params)}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },

    {
      field: "fabricante",
      headerName: "FABRICANTE",
      editable: true,
      width: 150,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditCellAutoComplete
            {...params}
            lista={predefinicoes}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "funcao",
      headerName: "FUNÇÃO",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <RenderFuncaoEditCellWithMemo
            params={params}
            predefinicoes={predefinicoes}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "modelo",
      headerName: "MODELO",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <RenderModeloEditCellWithMemo
            params={params}
            predefinicoes={predefinicoes}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "coletor",
      headerName: "COLETOR",
      width: 200,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <RenderEditCellWithMemo
            params={params}
            proxys={proxys}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "interface",
      headerName: "INTERFACE",
      width: 120,
      type: "",
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        const tipos = [{ interface: "AGENTE" }, { interface: "SNMP" }];

        return (
          <EditCellAutoCompleteInterface
            {...params}
            lista={tipos}
            setIsEdit={setIsEdit}
            setHostsEdit={setHostsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "tipoSnmp",
      headerName: "TIPO SNMP",
      width: 100,
      type: "",
      hide: true,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        const tipos = [{ tipoSnmp: "SNMPv1" }, { tipoSnmp: "SNMPv2" }];
        return (
          <EditCellAutoComplete
            {...params}
            lista={tipos}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "portaSnmp",
      headerName: "PORTA SNMP",
      width: 80,
      hide: true,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "comunidade",
      headerName: "COMUNIDADE",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },

    {
      field: "userTelnet",
      headerName: "USER TELNET",
      width: 100,
      hide: true,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "portaTelnet",
      headerName: "PORTA TELNET",
      width: 110,
      hide: true,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "senhaTelnet",
      headerName: "SENHA TELNET",
      width: 110,
      hide: true,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "userSsh",
      headerName: "USER SSH",
      width: 100,
      hide: true,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "portaSsh",
      headerName: "PORTA SSH",
      width: 100,
      hide: true,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "senhaSsh",
      headerName: "SENHA SSH",
      width: 100,
      hide: true,
      editable: true,
      renderCell: (params) => (
        <CellExibition
          {...params}
          selected={
            cellSelected?.id == params.id && cellSelected?.field == params.field
          }
        />
      ),
      renderEditCell: (params) => {
        return (
          <EditcellTextField
            {...params}
            setIsEdit={setIsEdit}
            selected={
              cellSelected?.id == params.id &&
              cellSelected?.field == params.field
            }
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "AÇÕES",
      //sortable: false,
      align: "center",
      headerAlign: "center",
      width: 70,

      editable: true,
      sortable: false,

      renderCell: (params) => {
        return !editMode ? (
          <Actions
            params={params}
            updateStateHost={updateStateHost}
            disabled={loadingUpdate}
          />
        ) : (
          <ActionsEditMode params={params} />
        );
      },
      //renderEditCell: ActionsEditMode,
    },
  ];

  const camposNulos = (host) => {
    return Object.keys(host).every((key) => {
      if (key !== "id" && key !== "newHost" && key !== "actions")
        return !host[key];
      else return true;
    });
  };

  const handleValidateData = useCallback((hosts) => {
    const verify = [...hosts]?.every((host) => {
      if (camposNulos(host) && host?.newHost) return true;
      return (
        !!host?.nome &&
        !!host?.ip &&
        validateIp(host?.ip) &&
        validaColunaNome({ value: host?.nome, id: host?.id }) == null
      );
    });
    if (!verify)
      setSnackbar({
        severity: "error",
        children: "Alguns campos não foram preenchidos corretamente",
      });
    return verify;
  }, []);

  return (
    <>
      <Box sx={stylesPrecadastro.container}>
        <Accordion
          square
          sx={stylesPrecadastro.accordion}
          elevation={3}
          defaultExpanded={true}
        >
          <AccordionSummary
            aria-controls="previous-regsiter-content"
            id="previous-regsiter-header"
            expandIcon={<ExpandMore />}
          >
            <Typography color={"primary"} sx={stylesPrecadastro.titles}>
              Pré-cadastro de Hosts
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <DataGrid
                apiRef={apiRef}
                key={"gridKey"}
                density="compact"
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[25, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                editMode="cell"
                autoHeight={hosts.length < 18}
                onColumnHeaderClick={() => {
                  setCellSelected(null);
                }}
                processRowUpdate={processRowUpdate}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                  if (Object.keys(newModel).length == 0) {
                    setColumnVisibilityModel((data) => {
                      Object.keys(data).map((key) => {
                        data[key] = true;
                      });
                      return { ...data };
                    });
                  } else {
                    setColumnVisibilityModel((data) => ({
                      ...data,
                      ...newModel,
                    }));
                  }
                }}
                loading={loading}
                sx={stylesPrecadastro.dataGrid}
                onCellKeyDown={handleCellKeyDown}
                onCellEditStart={handleCellEditStart}
                onCellEditStop={handleCellEditStop}
                onCellClick={handleCellSelect}
                onCellDoubleClick={(params, event) => {
                  event.defaultMuiPrevented = true;
                  event.stopPropagation();
                  handleCellClick(params, event);
                }}
                rows={editMode ? hostsEdit : hosts}
                columns={columns}
                cellModesModel={cellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                slots={{
                  toolbar: CustomToolbar,
                  pagination: CustomPagination,
                }}
                slotProps={{
                  toolbar: {
                    hosts,
                    setEditMode,
                    editMode,
                    setCellModesModel,
                    setHostsEdit,
                    updateHosts,
                    setCellSelected,
                  },
                  pagination: {
                    setCellSelected,
                  },
                }}
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick={true}
                disableRowSelectionOnClick={true}
              /> */}
            <DataGridWithNavigation
              columnVisibilityModel={columnVisibilityModel}
              setColumnVisibilityModel={setColumnVisibilityModel}
              loading={loading}
              style={stylesPrecadastro.dataGrid}
              rows={hosts}
              columns={columns}
              setEditMode={setEditMode}
              editMode={editMode}
              setCellModesModel={setCellModesModel}
              cellModesModel={cellModesModel}
              setRowsEdit={setHostsEdit}
              rowsEdit={hostsEdit}
              updateHosts={updateHosts}
              cellSelected={cellSelected}
              setCellSelected={setCellSelected}
              updateRows={updateHosts}
              setIsEdit={setIsEdit}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Dialog
        open={openAlertDialog}
        onClose={() => {
          setAlertContent("");
          setAlertTitle("");
          setOpenAlertDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: 3 }}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertContent("");
                setAlertTitle("");
                setOpenAlertDialog(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          {alertTitle}
          <Box
            component={"p"}
            sx={{
              maxWidth: "500px" /* Defina a largura máxima desejada */,
              wordWrap:
                "break-word" /* Esta propriedade faz com que o texto quebre quando necessário */,
            }}
          >
            <strong> {alertContent}</strong>
          </Box>
          {/* <Button
            onClick={() => {
              setOpenAlertDialog(false);
            }}
            autoFocus
          >
            Fechar
          </Button>*/}
        </Alert>
      </Dialog>
    </>
  );
}
