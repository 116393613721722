import {
  Paper,
  Stack,
  Typography,
  Button,
  Box,
  Modal,
  useTheme,
  Popper,
  IconButton,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconTransform from "../../iconsElement";

export default function ListIcon({ props, option, handleOnDelete = () => {} }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const mouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const mouseOut = (event) => {
    setAnchorEl(null);
  };
  const styles = {
    triangulo: {
      mt: "25px",
      mr: "30px",
      width: "0",
      height: "0",
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      borderLeft: "10px solid " + theme.palette.background.paperNotification,
    },
    container: {
      backgroundColor: theme.palette.background.paperNotification,
      padding: "10px 15px",
      borderRadius: "3px",
      direction: "column",
      alignItems: "center",
      gap: 0.5,
    },
  };
  const handleDelete = (event, id) => {
    event.defaultMuiPrevented = true;
    event.stopPropagation();
    handleOnDelete(id);
  };

  return (
    <>
      <li {...props}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack id={option.nome} gap={1} direction="row" alignItems="center">
            <Box
              onMouseEnter={mouseEnter}
              onMouseOut={mouseOut}
              sx={{
                width: "22px",
                height: "22px",
                position: "absolute",
              }}
            />

            <IconTransform img={option.img} size="22px" />
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap", // Adiciona esta linha
                width: "190px",
              }}
            >
              {option.nome.replace("_", "")}
            </Typography>
          </Stack>
          {!option.nome.startsWith("_") && (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(event) => handleDelete(event, option.id)}
            >
              <DeleteIcon
                sx={{ fontSize: "20px", color: theme.palette.primary.main }}
              />
            </IconButton>
          )}
        </Stack>
      </li>
      <Popper
        placement="left"
        sx={{ zIndex: 1500, display: "flex", direction: "row" }}
        id={option.nome + "aa"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <Stack sx={styles.container}>
          <Box sx={{ height: "50px" }}>
            <IconTransform img={option.img} size="50px" />
          </Box>
        </Stack>

        <Box sx={styles.triangulo} />
      </Popper>
    </>
  );
}
