import { memo } from "react";
import EditCellAutoComplete from "../../../componentes/easyAdd/editCellAutoComplete";

const validaColObrigatoria = (params) => {
  let errorMessage = null;
  errorMessage = !params?.value ? "Campo obrigatório" : null;
  return errorMessage;
};

// Componente renderEditCell otimizado com React.memo
export const RenderModeloEditCellWithMemo = memo(
  ({ params, predefinicoes, setIsEdit, selected }) => {
    // Encontra as funções predefinidas para o fabricante específico
    const { Funcoes: funcoes = [] } =
      predefinicoes?.find(
        ({ fabricante }) =>
          fabricante?.toLowerCase() === params.row?.fabricante?.toLowerCase()
      ) || {};

    // Encontra os modelos predefinidos para a função específica
    const { Modelos: modelos = [] } =
      funcoes?.find(
        ({ funcao }) =>
          funcao?.toLowerCase() === params.row?.funcao?.toLowerCase()
      ) || {};

    return (
      <EditCellAutoComplete
        {...params}
        lista={modelos}
        setIsEdit={setIsEdit}
        selected={selected}
      />
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou predefinicoes mudarem
    return (
      prevProps.params.value?.toLowerCase() ===
        nextProps.params.value?.toLowerCase() &&
      prevProps.params.row.fabricante?.toLowerCase() ===
        nextProps.params.row.fabricante?.toLowerCase() &&
      prevProps.params.row.funcao?.toLowerCase() ===
        nextProps.params.row.funcao?.toLowerCase()
    );
  }
);

export const RenderEditCellWithMemo = memo(
  ({ params, proxys, setIsEdit, selected }) => {
    let proxs = proxys.map((a) => ({ coletor: a.host }));

    return (
      <EditCellAutoComplete
        {...params}
        lista={proxs}
        error={validaColObrigatoria(params)}
        setIsEdit={setIsEdit}
        selected={selected}
      />
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou proxys mudarem
    return (
      prevProps.params.value?.toLowerCase() ===
        nextProps.params.value?.toLowerCase() &&
      prevProps.proxys === nextProps.proxys
    );
  }
);

// Componente renderEditCell otimizado com React.memo
export const RenderFuncaoEditCellWithMemo = memo(
  ({ params, predefinicoes, setIsEdit, selected }) => {
    // Encontra as funções predefinidas para o fabricante específico
    const { Funcoes: funcoes = [] } =
      predefinicoes.find(
        ({ fabricante }) =>
          fabricante?.toLowerCase() === params.row?.fabricante?.toLowerCase()
      ) || {};

    return (
      <EditCellAutoComplete
        {...params}
        lista={funcoes}
        setIsEdit={setIsEdit}
        selected={selected}
      />
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou predefinicoes mudarem
    return (
      prevProps.params.value?.toLowerCase() ===
        nextProps.params.value?.toLowerCase() &&
      prevProps.params.row.fabricante?.toLowerCase() ===
        nextProps.params.row.fabricante?.toLowerCase()
    );
  }
);
