import {
  Paper,
  Stack,
  Tabs,
  Tab,
  Box,
  Divider,
  Typography,
  useTheme,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import React, { useEffect } from "react";
import { useState } from "react";
import CardMaps from "./cardMaps";
import CardAlerts from "./cardAlerts";
import CardHosts from "./cardHosts";
//icons
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import SearchIcon from "@mui/icons-material/Search";

import {
  Dangerous,
  Error,
  LocalFireDepartment,
  ReportProblem,
} from "@mui/icons-material";

export default function SliderBarMaps(props) {
  const {
    map,
    iconsElements,
    setFocusNodeId = () => {},
    mapRef,
    elements,
  } = props;

  const [alertasTotais, setAlertasTotais] = useState([]);
  const [mapasConectados, setMapasConectados] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [filterHosts, setFilterHosts] = useState("");
  const [filterAlerts, setFilterAlerts] = useState(0);
  const [filterMaps, setFilterMaps] = useState("");
  const [valueTab, setValueTab] = useState(1);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const styles = {
    data: {
      overflow: "auto",
      height: "100%",
      paddingX: "6px",
      paddingBottom: "15px",
    },
    menuItem: {
      display: "flex",
      alignItems: "center",
    },
    iconItem: { mr: 1 },
    containerData: {
      overflow: "hidden",
      height: "100%",
    },
    paper: {
      borderRadius: "0px",
      height: "calc(100vh - 65px)",
      position: "sticky",
      top: "65px",
      maxWidth: "310px!important",
      minWidth: "310px!important",
      padding: "0px 10px",
      backgroundColor: theme.palette.background.slideBar,
    },
    cardNoMaps: {
      background: theme.palette.background.paperSelectedMaps,
      height: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: "0.875rem",
      color: theme.palette.color.text,
      //textTransform: "none",
    },
  };
  useEffect(() => {
    setAlertasTotais(
      elements?.flatMap((ele) =>
        ele.alerts?.map((alert) => ({ ...alert, elementId: ele.id }))
      )
    );
    setMapasConectados(elements?.filter((e) => e.tipo == 1 || e.tipo == 2));
    setHosts(elements?.filter((e) => e.tipo == 0));
  }, [elements]);

  const CardNoMaps = () => {
    return (
      <Paper elevation={0} sx={styles.cardNoMaps}>
        <Typography
          sx={styles.text}
          color={theme.palette.mode == "dark" ? "#ffffff6e" : ""}
        >
          Não há mapas
        </Typography>
      </Paper>
    );
  };

  function search(id) {
    setFocusNodeId(id);
    if (mapRef?.current) {
      mapRef.current.focusZoomExt(id);
    }
  }

  return (
    <Stack sx={styles.paper}>
      <Stack direction="column" gap={2} height="calc(100vh - 65px)">
        <Box>
          <Tabs
            variant="fullWidth"
            value={valueTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab
              icon={<MapOutlinedIcon />}
              label="Mapas"
              value={1}
              sx={styles.text}
            />
            <Tab
              sx={styles.text}
              icon={<BoltOutlinedIcon />}
              label="Alertas"
              value={2}
            />
            <Tab
              sx={styles.text}
              icon={<StorageOutlinedIcon />}
              label="Hosts"
              value={3}
            />
          </Tabs>
          <Divider />
        </Box>

        <Stack gap={2} sx={styles.containerData}>
          {valueTab == 1 && (
            <>
              <TextField
                id="outlined-basic"
                label="Busca"
                variant="outlined"
                size="small"
                value={filterMaps}
                onChange={(event) => setFilterMaps(event.target.value)}
                sx={{ width: "100%", mt: 1 }}
                slotProps={{
                  input: {
                    endAdornment: <SearchIcon />,
                  },
                }}
              />
              {mapasConectados?.length == 0 && <CardNoMaps />}
              <Stack sx={styles.data} gap={1}>
                {mapasConectados
                  ?.filter((mapa) =>
                    mapa?.titulo
                      ?.toLowerCase()
                      ?.includes(filterMaps.toLowerCase())
                  )
                  ?.map((map, index) => (
                    <Button
                      onClick={() => {
                        search(map.id);
                      }}
                      sx={{
                        padding: "0px",
                      }}
                      key={"elementoMap" + index}
                    >
                      <CardMaps
                        key={"mapConnect" + index}
                        variant="linked"
                        title={map?.titulo}
                        alertas={map?.alerts?.length}
                        link={"#"}
                        tipo={map?.tipo}
                      />
                    </Button>
                  ))}{" "}
              </Stack>

              {/* <Stack direction="row" gap={2} alignItems="center">
                <MapOutlinedIcon color="primary" />
                <Typography sx={styles.text}>Mapas do Grupo</Typography>
            </Stack>*/}
            </>
          )}

          {valueTab == 2 && (
            <>
              <FormControl sx={{ mt: 1 }}>
                <InputLabel id="demo-simple-select-label">Filtro</InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterAlerts}
                  label="Filtro"
                  onChange={(event) => {
                    setFilterAlerts(event.target.value);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      display: "flex!important",
                    },
                  }}
                >
                  <MenuItem sx={styles.menuItem} value={0}>
                    {" "}
                    Todos
                  </MenuItem>
                  <Divider />
                  <MenuItem sx={styles.menuItem} value={5}>
                    <LocalFireDepartment sx={styles.iconItem} />
                    Desatre
                  </MenuItem>
                  <MenuItem sx={styles.menuItem} value={4}>
                    <Dangerous sx={styles.iconItem} />
                    Alto impacto
                  </MenuItem>
                  <MenuItem sx={styles.menuItem} value={3}>
                    <Error sx={styles.iconItem} />
                    Moderado
                  </MenuItem>
                  <MenuItem sx={styles.menuItem} value={2}>
                    <ReportProblem sx={styles.iconItem} />
                    Alerta
                  </MenuItem>
                </Select>
              </FormControl>

              <Stack direction={"column"} gap={1} sx={styles.data}>
                {alertasTotais
                  ?.filter((alert) => {
                    if (filterAlerts == 0) return true;
                    return alert.severity == filterAlerts;
                  })
                  ?.map((alerta, index) => (
                    <CardAlerts
                      key={"aletas" + index}
                      alert={alerta}
                      setFocusNodeId={search}
                    />
                  ))}
              </Stack>
            </>
          )}

          {valueTab == 3 && (
            <>
              <TextField
                id="outlined-basic"
                label="Busca"
                variant="outlined"
                size="small"
                value={filterHosts}
                onChange={(event) => setFilterHosts(event.target.value)}
                sx={{ width: "100%", mt: 1 }}
                slotProps={{
                  input: {
                    endAdornment: <SearchIcon />,
                  },
                }}
              />
              <Stack direction={"column"} gap={1} sx={styles.data}>
                {hosts
                  ?.filter((host) =>
                    host?.titulo
                      ?.toLowerCase()
                      ?.includes(filterHosts.toLowerCase())
                  )
                  ?.map((host, index) => (
                    <Button
                      onClick={() => {
                        search(host.id);
                      }}
                      sx={{
                        padding: "0px",
                      }}
                      key={"elementoHost" + index}
                    >
                      <CardHosts host={host} iconsElement={iconsElements} />
                    </Button>
                  ))}
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
