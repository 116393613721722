import { ptBR } from "@mui/x-data-grid/locales";
import CustomToolbar from "../customToolBar";
import { CustomPagination } from "../customPagination";
import { DataGrid, GridCellModes, useGridApiRef } from "@mui/x-data-grid";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Stack } from "@mui/material";

export const DataGridWithNavigation = forwardRef(
  (
    {
      columnVisibilityModel = {},
      setColumnVisibilityModel = () => {},
      loading = false,
      style = null,
      rows = [],
      columns = [],
      setEditMode = () => {},
      editMode = false,
      setCellModesModel = () => {},
      cellModesModel = null,
      setRowsEdit = () => {},
      rowsEdit = [],
      updateRows = () => {},
      cellSelected = null,
      setCellSelected = () => {},
      setIsEdit = () => {},
    },
    ref
  ) => {
    const apiRef = useGridApiRef();
    const containerRef = useRef(null);

    // useImperativeHandle(ref, () => ({
    //   getMode() {
    //     return editMode;
    //   },
    //   setMode(mode) {
    //     setEditMode(mode);
    //   },
    //   gridApi() {
    //     return apiRef.current;
    //   },
    // }));

    const handleCellSelect = useCallback(
      (params, event) => {
        if (!editMode) return;
        setCellSelected({ id: params.id, field: params.field });
      },
      [editMode]
    );
    const handleCellClick = useCallback(
      (params, event) => {
        if (!editMode) return;
        if (!params.isEditable) {
          return;
        }
        // Ignore portal
        if (
          event.target.nodeType === 1 &&
          !event.currentTarget.contains(event.target)
        ) {
          return;
        }

        setCellModesModel((prevModel) => {
          return {
            // Revert the mode of the other cells from other rows
            ...Object.keys(prevModel).reduce(
              (acc, id) => ({
                ...acc,
                [id]: Object.keys(prevModel[id]).reduce(
                  (acc2, field) => ({
                    ...acc2,
                    [field]: { mode: GridCellModes.View },
                  }),
                  {}
                ),
              }),
              {}
            ),
            [params.id]: {
              // Revert the mode of other cells in the same row
              ...Object.keys(prevModel[params.id] || {}).reduce(
                (acc, field) => ({
                  ...acc,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
              [params.field]: { mode: GridCellModes.Edit },
            },
          };
        });
      },
      [editMode]
    );
    const handleCellModesModelChange = useCallback((newModel) => {
      setCellModesModel(newModel);
    }, []);

    const handleCellEditStop = (params, event) => {
      //eventos durante a edição
      if (params.reason === "enterKeyDown") {
        apiRef.current.stopCellEditMode({
          id: params.id,
          field: params.field,
        });
        moveDatagrideCellAxisY(params, event, 1);
      }
      if (params.reason === "tabKeyDown") {
        apiRef.current.stopCellEditMode({
          id: params.id,
          field: params.field,
        });
        moveDatagrideCellAxisX(params, event, 1);
      }
    };
    const handleCellEditStart = (params, event) => {
      event.defaultMuiPrevented = true;
      event.preventDefault();
      if (!editMode) return;
      //Evento para entrar na edição
      if (
        params.reason === "enterKeyDown" ||
        params.reason == "printableKeyDown"
      ) {
        if (!cellSelected.id) return;
        apiRef.current.startCellEditMode({
          id: cellSelected.id,
          field: cellSelected.field,
        });
      }
    };
    const handleCellKeyDown = (params, event) => {
      // evento do pre-select
      if (!editMode) return;
      if (cellModesModel[params.id]?.[params.field]?.mode == "edit") return;

      if (event.code === "ArrowLeft") {
        moveDatagrideCellAxisX(params, event, -1);
      }
      if (event.code === "ArrowRight") {
        moveDatagrideCellAxisX(params, event, 1);
      }
      if (event.code === "ArrowUp") {
        moveDatagrideCellAxisY(params, event, -1);
      }
      if (event.code === "ArrowDown") {
        moveDatagrideCellAxisY(params, event, 1);
      }
      if (event.code === "Tab") {
        moveDatagrideCellAxisX(params, event, 1);
      }
    };

    const moveDatagrideCellAxisX = (params, event, direction) => {
      event.preventDefault(); // Impede que o foco mude para outro elemento
      event.defaultMuiPrevented = true;
      let keys = Object.keys(columnVisibilityModel).filter(
        (key) => columnVisibilityModel[key] == true && key != "id"
      );
      let indexAtual = keys.indexOf(cellSelected.field);
      let nextIndex;
      if (indexAtual == keys.length - 1 && direction > 0) return;
      else if (indexAtual == 0 && direction < 0) return;
      else nextIndex = indexAtual + direction;
      setCellSelected({
        id: cellSelected.id,
        field: keys[nextIndex],
      });
      // scroll
      let rows = apiRef.current.getAllRowIds();
      let indexRow = rows.indexOf(cellSelected.id);
      apiRef.current.scrollToIndexes({
        rowIndex: indexRow,
        colIndex: nextIndex,
      });
    };

    const moveDatagrideCellAxisY = (params, event, direction) => {
      event.preventDefault(); // Impede que o foco mude para outro elemento
      event.defaultMuiPrevented = true;
      let rows = apiRef.current.getAllRowIds();
      let indexAtual = rows.indexOf(cellSelected.id);
      let nextIndex;
      if (indexAtual == rows.length - 1 && direction > 0) return;
      else if (indexAtual == 0 && direction < 0) return;
      else nextIndex = indexAtual + direction;
      setCellSelected({
        id: rows[nextIndex],
        field: cellSelected.field,
      });
      // scroll
      let colIndex = apiRef.current.getColumnPosition({
        field: cellSelected.field,
      });
      apiRef.current.scrollToIndexes({ rowIndex: nextIndex, colIndex });
    };

    useEffect(() => {
      // Adiciona o listener para detectar cliques fora do DataGrid
      document.addEventListener("mousedown", handleClickOutside);
      // Remove o listener quando o componente for desmontado
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setCellSelected(null);
      }
    };
    const processRowUpdate = useCallback(async (newRow, oldRow) => {
      const { newHost, ...newRowWithoutNewRow } = newRow;

      setIsEdit((data) => data.filter((a) => a != newRowWithoutNewRow.id));

      setRowsEdit((prev) => {
        // Verifica se já existe uma linha com o mesmo id
        const index = prev.findIndex(
          (row) => row.id === newRowWithoutNewRow.id
        );
        // Se existir, substitui a linha existente; caso contrário, adiciona a nova linha
        if (index !== -1) {
          // Substitui a linha existente
          return prev.map((row, i) =>
            i === index ? newRowWithoutNewRow : row
          );
        } else {
          // Adiciona a nova linha
          return [...prev, newRowWithoutNewRow];
        }
      });

      return newRow;
    }, []);

    return (
      <Stack
        ref={containerRef}
        sx={{
          height:
            (editMode ? rowsEdit : rows).length > 18
              ? "800px"
              : (editMode ? rowsEdit : rows).length == 0
              ? "300px"
              : (editMode ? rowsEdit : rows).length * 36 + 164 + "px",
        }}
      >
        <DataGrid
          apiRef={apiRef}
          key={"gridKey"}
          density="compact"
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          pageSizeOptions={[25, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          editMode="cell"
          onColumnHeaderClick={() => {
            setCellSelected(null);
          }}
          processRowUpdate={processRowUpdate}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            if (Object.keys(newModel).length == 0) {
              setColumnVisibilityModel((data) => {
                Object.keys(data).map((key) => {
                  data[key] = true;
                });
                return { ...data };
              });
            } else {
              setColumnVisibilityModel((data) => ({
                ...data,
                ...newModel,
              }));
            }
          }}
          loading={loading}
          sx={style}
          onCellKeyDown={handleCellKeyDown}
          onCellEditStart={handleCellEditStart}
          onCellEditStop={handleCellEditStop}
          onCellClick={handleCellSelect}
          onCellDoubleClick={(params, event) => {
            event.defaultMuiPrevented = true;
            event.stopPropagation();
            handleCellClick(params, event);
          }}
          rows={editMode ? rowsEdit : rows}
          columns={columns}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          slots={{
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          slotProps={{
            toolbar: {
              hosts: rows,
              setEditMode,
              editMode,
              setCellModesModel,
              setHostsEdit: setRowsEdit,
              updateHosts: updateRows,
              setCellSelected,
            },
            pagination: {
              setCellSelected,
            },
          }}
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick={true}
          disableRowSelectionOnClick={true}
        />
      </Stack>
    );
  }
);
