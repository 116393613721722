import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  FormControlLabel,
  InputAdornment,
  Tab,
  TextField,
  Typography,
  Checkbox,
  Box,
  FormGroup,
  Modal,
  Stack,
  Paper,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Radio,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import AlertModal from "../../../../../componentes/alertModal";
import SnackBar from "../../../../../componentes/snackbar";
const styles = {
  confItensTextField: {
    width: "130px",
    "&::-webkit-timer-picker-indicator": {
      display: "none",
      "-webkit-appearance": "none",
    },
  },
  container: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  paper: {
    borderRadius: 3,
    padding: "10px",
  },
  boxTabList: { borderRight: 1, borderColor: "divider" },
  tabPanel: { maxWidth: "1100px" },
  itensName: { opacity: 0.8, fontSize: "14px" },
  formGroup: {
    gap: 3,
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "500px",
    alignItems: "start",
  },
  formControlLabel: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    gap: 1,
  },
  checkbox: { padding: "0" },
};
export default function ModalConfigItens(props) {
  const { openModalConfig, setOpenModalConfig, hosts } = props;
  const [snackbar, setSnackbar] = useState(null);
  const [listItens, setListItens] = useState([]);
  const [listTags, setListTags] = useState([]);
  const initialHost = {
    id: "",
    nome: "",
    itens: [],
  };
  const [selectedHosts, setSelectedHosts] = useState([initialHost]);
  const [prevListTags, setPrevListTags] = useState([]);
  const [prevListItens, setPrevListItens] = useState([]);
  const [tabValue, setTabValue] = useState("0");
  const [disableButton, setDisableButton] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [ocultarGraficos, setOcultarGraficos] = useState(false);
  const [prevOcultarGraficos, setPrevOcultarGraficos] = useState(false);
  const theme = useTheme();

  const tabsText = [
    {
      label: "Valor Máximo",
      description: "Exibe o valor máximo dentro do período de coleta",
      key: "valorMaximo",
      tag: ["traffic", "number"],
    },
    {
      label: "Valor Mínimo",
      description: "Exibe o valor mínimo dentro do período de coleta",
      key: "valorMinimo",
      tag: ["traffic", "number"],
    },
    {
      label: "Valor Médio",
      description: "Exibe a média dos valores dentro do período de coleta",
      key: "valorMedio",
      tag: ["traffic", "number"],
    },
    {
      label: "Taxa de disponibilidade",
      description:
        "Exibe os períodos em que os hosts estiveram disponíveis e indisponíveis",
      key: "disponibilidade",
      tag: ["traffic", "boolean", "number"],
    },
    {
      label: "Horário personalizado",
      description:
        "Restringe a análise de dados somente ao intervalo de tempo selecionado durante o período de coleta",
      key: "horarioPersonalizado",
      tag: ["traffic", "boolean", "number"],
    },
    {
      label: "Valor mínimo para disponibilidade",
      description:
        "Define o valor mínimo de tráfego para o host será considerado disponível",
      key: "zeroDown",
      tag: ["traffic"],
    },
    {
      label: "95th (noventa e cinco percentil)",
      description:
        "Exibe tarifa de uso baseada no método de cobrança 95 percentil",
      key: "tarifacao",
      tag: ["traffic"],
    },
    {
      label: "95th com cobrança por excedente",
      description:
        "Exibe tarifa de uso baseada no método de cobrança 95 percentil com adição de cobrança extra para caso de consumo excedente",
      key: "tarifacaoExcedente",
      tag: ["traffic"],
    },
    /*   {
      label: "Variação abrupta",
      description: "Adiciona análise de quedas e subidas abruptas ao relatório",
      key: "variacaoAbrupta",
    }, */
  ];
  const onCloseModal = () => {
    setListTags(prevListTags);
    setListItens(prevListItens);
    setOcultarGraficos(prevOcultarGraficos);
    setOpenModalConfig(false);
  };

  const onButtonSave = () => {
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    if (Array.isArray(hosts) && hosts.length > 0) setSelectedHosts(hosts);
  }, [hosts]);

  useEffect(() => {
    setOcultarGraficos(selectedHosts[0]?.itens[0]?.configuracoes?.hideCharts);
    setPrevOcultarGraficos(
      selectedHosts[0]?.itens[0]?.configuracoes?.hideCharts
    );
    //remove array mais externo
    const flatResponse = selectedHosts.flatMap((obj) => obj?.itens);
    // cria o array de intens
    const newTagArray = Array.from(
      new Map(flatResponse.map((item) => [item.tag, item])).values()
    );
    const newItemArray = Array.from(
      new Map(flatResponse.map((item) => [item.nome, item])).values()
    );

    setListItens(newItemArray);
    setPrevListItens(newItemArray);
    setPrevListTags(
      newTagArray.sort((a, b) => pesoDaTag(a.tag) - pesoDaTag(b.tag))
    );
    //criar um valor unico para todos todos os itens de tráfico
    setListTags(
      newTagArray.sort((a, b) => pesoDaTag(a.tag) - pesoDaTag(b.tag))
    );
  }, [selectedHosts]);

  function pesoDaTag(tag) {
    switch (tag) {
      case "traffic":
        return 1;
      case "number":
        return 2;
      case "boolean":
        return 3;
      default:
        return 4; // Qualquer outro valor
    }
  }

  const handleListItensChange = (index, event) => {
    const { name, checked, value } = event.target;
    let newListItens = JSON.parse(JSON.stringify(listTags));

    if (!newListItens[index].configuracoes) {
      newListItens[index].configuracoes = {
        variacaoAbrupta: { status: false },
        tarifacao: { status: true, valor: 0 },
        tarifacaoExcedente: {
          status: false,
          valorExcedente: 0,
          limite: 0,
          valor: 0,
        },
        //zeroDown: false,
        zeroDown: { status: true, valor: 0 },
        horarioPersonalizado: { status: false },
        valorMaximo: true,
        valorMinimo: true,
        valorMedio: true,
        disponibilidade: false,
      };
    }

    switch (name) {
      //tarifacao
      case "tarifacao":
        newListItens[index].configuracoes[name].status = checked;
        if (checked)
          newListItens[index].configuracoes["tarifacaoExcedente"].status =
            !checked;
        break;
      case "tarifacao-valor":
        newListItens[index].configuracoes["tarifacao"].valor = value
          ? value
          : 0;
        break;
      //tarifacao excedente
      case "tarifacaoExcedente":
        newListItens[index].configuracoes[name].status = checked;
        if (checked)
          newListItens[index].configuracoes["tarifacao"].status = !checked;
        break;
      case "tarifacaoExcedente-valor":
        newListItens[index].configuracoes["tarifacaoExcedente"].valor = value
          ? value
          : 0;
        break;
      case "tarifacaoExcedente-valorExcedente":
        newListItens[index].configuracoes["tarifacaoExcedente"].valorExcedente =
          value ? value : 0;
        break;
      case "tarifacaoExcedente-limite":
        newListItens[index].configuracoes["tarifacaoExcedente"].limite = value
          ? value
          : 0;
        break;
      //horario
      case "horarioPersonalizado":
        newListItens[index].configuracoes[name].status = checked;
        break;

      case "horaInicial":
        newListItens[index].configuracoes["horarioPersonalizado"].horaInicial =
          value;
        break;

      case "horaFinal":
        newListItens[index].configuracoes["horarioPersonalizado"].horaFinal =
          value;
        break;
      // variacaoAbrupta
      case "variacaoAbrupta":
        newListItens[index].configuracoes[name].status = checked;
        break;
      case "variacao":
        newListItens[index].configuracoes["variacaoAbrupta"].variacao = value;
        break;
      // valor minimo para disponibilidade
      case "zeroDown":
        const itenZeroDown = newListItens[index].configuracoes["zeroDown"];
        if (itenZeroDown.hasOwnProperty("status"))
          newListItens[index].configuracoes["zeroDown"].status = checked;
        else
          newListItens[index].configuracoes.zeroDown = {
            status: checked,
            valor: 0,
          };
        // newListItens[index].configuracoes[name].status = checked;
        if (checked)
          newListItens[index].configuracoes["disponibilidade"] = true;
        break;
      case "zeroDown-valor":
        newListItens[index].configuracoes["zeroDown"].valor = value;
        break;
      default:
        newListItens[index].configuracoes[name] = checked;
        break;
    }
    setListTags(newListItens);
  };
  const handleOcultarGraficos = (event) => {
    const { name, value, checked } = event.target;
    const newFeature = checked;
    setOcultarGraficos(newFeature);
  };
  async function handleApiPutHosts() {
    try {
      setDisableButton(true);

      const response = await api.put("/easyReport/hosts/update", {
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
        hosts: selectedHosts.map((host) => {
          let newItensArray = [];
          host.itens.map((item) => {
            // const newItem = listTags.find(
            //   ({ itemId }) => item.itemId === itemId
            // );
            let newItem = [];
            listTags.map((itemConfig) => {
              if (item?.tag === itemConfig?.tag) {
                const novoItem = {
                  ...item,
                  configuracoes: {
                    ...itemConfig?.configuracoes,
                    hideCharts: ocultarGraficos,
                  },
                };

                novoItem.itemId = item?.itemid;
                novoItem.item = item?.nome;
                newItem.push(novoItem);
              }
            });

            delete newItem[0]?.itemid;
            delete newItem[0]?.value_type;
            delete newItem[0]?.units;
            //delete newItem[0]?.nome;
            delete newItem[0]?.name;
            //delete newItem[0]?.tag;

            newItensArray.push(newItem);
            // newItensArray.push({
            //   ...newItem,
            //   configuracoes: {
            //     ...newItem.configuracoes,
            //     hideCharts: ocultarGraficos,
            //   },
            // });
          });
          return { ...host, itens: newItensArray.flat() };
        }),
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    } finally {
      setDisableButton(false);
      setOpenConfirmDialog(false);
      setOpenModalConfig(false);
    }
  }

  return (
    <>
      <Modal open={openModalConfig} onClose={onCloseModal}>
        <Box sx={styles.container}>
          <Paper sx={styles.paper} elevation={3}>
            <DialogTitle color="primary">
              Edição de Confirações de Itens{" "}
            </DialogTitle>
            <DialogContent>
              <FormGroup sx={{ width: "fit-content" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ocultarGraficos}
                      size="small"
                      name="chart"
                      onChange={(event) => {
                        handleOcultarGraficos(event);
                      }}
                    />
                  }
                  label="Ocultar gráficos"
                />
              </FormGroup>
              <TabContext value={tabValue}>
                <Box sx={styles.boxTabList}>
                  <TabList
                    onChange={(event, newValue) => {
                      setTabValue(newValue);
                    }}
                  >
                    {listTags.map((item, index) => (
                      <Tab
                        label={
                          item.tag === "traffic"
                            ? "Itens de Tráfego"
                            : item.tag === "boolean"
                            ? "Itens Booleanos"
                            : "Itens genéricos"
                        }
                        value={`${index}`}
                        key={item.key_}
                      />
                    ))}
                  </TabList>
                </Box>
                {listTags.map((item, index) => (
                  <TabPanel
                    value={`${index}`}
                    key={`${item.key_}-${index}`}
                    sx={styles.tabPanel}
                  >
                    <Stack direction={"column"} gap={1} mb={2} ml={-1}>
                      <Typography>
                        Os seguintes itens seguiram as configuações a abaixo:{" "}
                      </Typography>
                      <Typography sx={styles.itensName}>
                        {listItens
                          ?.filter((listItem) => listItem.tag === item.tag)
                          ?.map(
                            (listItem, index) =>
                              `${listItem.nome || listItem.item}${
                                listItens?.filter(
                                  (listItem) => listItem.tag === item.tag
                                ).length -
                                  1 ===
                                index
                                  ? "."
                                  : ","
                              } `
                          )}
                      </Typography>
                    </Stack>

                    <FormGroup sx={styles.formGroup}>
                      {tabsText.map((text, subIndex) => {
                        if (text?.tag?.includes(item?.tag)) {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "start",
                                gap: 1,
                                flexDirection: "row",
                                width: subIndex > 4 ? "640px" : "auto",
                              }}
                              key={`${item.nome}-${index}-${subIndex}`}
                            >
                              <FormControlLabel
                                sx={styles.formControlLabel}
                                control={
                                  text.key === "horarioPersonalizado" ||
                                  text.key === "variacaoAbrupta" ? (
                                    <Checkbox
                                      name={text.key}
                                      checked={
                                        listTags[index]?.["configuracoes"]?.[
                                          text.key
                                        ]
                                          ? listTags[index]?.[
                                              "configuracoes"
                                            ]?.[text.key]?.status
                                          : false
                                      }
                                      onChange={(event) => {
                                        handleListItensChange(index, event);
                                      }}
                                      sx={styles.checkbox}
                                    />
                                  ) : text.key === "tarifacao" ||
                                    text.key === "tarifacaoExcedente" ? (
                                    <Radio
                                      name={text.key}
                                      checked={
                                        listTags[index]?.configuracoes?.[
                                          text.key
                                        ].status
                                          ? listTags[index]?.configuracoes?.[
                                              text.key
                                            ].status
                                          : false
                                      }
                                      onChange={(event) => {
                                        handleListItensChange(index, event);
                                      }}
                                      value={true}
                                      sx={styles.checkbox}
                                    />
                                  ) : text.key === "zeroDown" ? (
                                    <Checkbox
                                      name={text.key}
                                      checked={
                                        listTags[index]?.["configuracoes"]?.[
                                          text.key
                                        ]
                                          ? listTags[index]?.[
                                              "configuracoes"
                                            ]?.[text.key]?.status
                                          : false
                                      }
                                      onChange={(event) => {
                                        handleListItensChange(index, event);
                                      }}
                                      sx={styles.checkbox}
                                    />
                                  ) : (
                                    <Checkbox
                                      name={text.key}
                                      checked={
                                        listTags[index]?.configuracoes?.[
                                          text.key
                                        ]
                                          ? listTags[index]?.configuracoes?.[
                                              text.key
                                            ]
                                          : false
                                      }
                                      onChange={(event) => {
                                        handleListItensChange(index, event);
                                      }}
                                      sx={styles.checkbox}
                                    />
                                  )
                                }
                                label={
                                  <Stack direction={"row"}>
                                    <Stack
                                      direction={"column"}
                                      sx={{ width: "250px" }}
                                    >
                                      <Typography>
                                        {text?.label || ""}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          opacity: 0.61,
                                          fontFamily: "Roboto",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          lineHeight: "175%",
                                          letterSpacing: "0.15px",
                                        }}
                                      >
                                        {text?.description || ""}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                }
                              />
                              <Stack direction={"column"} gap={2}>
                                {text?.key === "zeroDown" &&
                                  listTags[index]?.configuracoes?.zeroDown
                                    ?.status && (
                                    <TextField
                                      name={"zeroDown-valor"}
                                      label={`Limiar`}
                                      type="number"
                                      size="small"
                                      autoComplete="off"
                                      //InputLabelProps={{ shrink: true }}
                                      slotProps={{
                                        inputLabel: { shrink: true },
                                      }}
                                      helperText={
                                        <Typography
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            ml: -1.5,
                                          }}
                                        >
                                          Em bps
                                        </Typography>
                                      }
                                      value={
                                        listTags[index]?.configuracoes?.zeroDown
                                          ?.valor
                                          ? listTags[index]?.configuracoes
                                              ?.zeroDown?.valor
                                          : null
                                      }
                                      onChange={(event) => {
                                        handleListItensChange(index, event);
                                      }}
                                      sx={styles.confItensTextField}
                                    />
                                  )}
                                {text?.key === "tarifacao" &&
                                  listTags[index]?.configuracoes?.tarifacao
                                    ?.status && (
                                    <TextField
                                      name={"tarifacao-valor"}
                                      label={`valor por unidade`}
                                      type="number"
                                      size="small"
                                      autoComplete="off"
                                      slotProps={{
                                        inputLabel: { shrink: true },
                                        input: {
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              R$
                                            </InputAdornment>
                                          ),
                                        },
                                      }}
                                      value={
                                        listTags[index]?.configuracoes
                                          ?.tarifacao?.valor
                                          ? listTags[index]?.configuracoes
                                              ?.tarifacao?.valor
                                          : null
                                      }
                                      onChange={(event) => {
                                        handleListItensChange(index, event);
                                      }}
                                      sx={styles.confItensTextField}
                                    />
                                  )}
                                {text?.key === "tarifacaoExcedente" &&
                                  listTags[index]?.configuracoes
                                    ?.tarifacaoExcedente?.status && (
                                    <>
                                      <TextField
                                        name={"tarifacaoExcedente-valor"}
                                        label={`valor por unidade`}
                                        type="number"
                                        size="small"
                                        autoComplete="off"
                                        slotProps={{
                                          inputLabel: { shrink: true },
                                          input: {
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                R$
                                              </InputAdornment>
                                            ),
                                          },
                                        }}
                                        value={
                                          listTags[index]?.configuracoes
                                            ?.tarifacaoExcedente?.valor
                                            ? listTags[index]?.configuracoes
                                                ?.tarifacaoExcedente?.valor
                                            : null
                                        }
                                        onChange={(event) => {
                                          handleListItensChange(index, event);
                                        }}
                                        sx={styles.confItensTextField}
                                      />
                                      <TextField
                                        name={"tarifacaoExcedente-limite"}
                                        label={`limite de tráfego`}
                                        type="number"
                                        size="small"
                                        autoComplete="off"
                                        slotProps={{
                                          inputLabel: { shrink: true },
                                        }}
                                        value={
                                          listTags[index]?.configuracoes
                                            ?.tarifacaoExcedente?.limite
                                            ? listTags[index]?.configuracoes
                                                ?.tarifacaoExcedente?.limite
                                            : null
                                        }
                                        onChange={(event) => {
                                          handleListItensChange(index, event);
                                        }}
                                        sx={styles.confItensTextField}
                                        helperText={
                                          <Typography
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 500,
                                              ml: -1.5,
                                            }}
                                          >
                                            Em Mbps
                                          </Typography>
                                        }
                                      />
                                      <TextField
                                        name={
                                          "tarifacaoExcedente-valorExcedente"
                                        }
                                        label={`valor do excedente`}
                                        type="number"
                                        size="small"
                                        autoComplete="off"
                                        //InputLabelProps={{ shrink: true }}
                                        slotProps={{
                                          inputLabel: { shrink: true },
                                          input: {
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                R$
                                              </InputAdornment>
                                            ),
                                          },
                                        }}
                                        value={
                                          listTags[index]?.configuracoes
                                            ?.tarifacaoExcedente?.valorExcedente
                                            ? listTags[index]?.configuracoes
                                                ?.tarifacaoExcedente
                                                ?.valorExcedente
                                            : null
                                        }
                                        onChange={(event) => {
                                          handleListItensChange(index, event);
                                        }}
                                        sx={styles.confItensTextField}
                                      />
                                    </>
                                  )}
                                {text.key === "horarioPersonalizado" &&
                                  listTags[index]?.configuracoes
                                    ?.horarioPersonalizado?.status && (
                                    <>
                                      <TextField
                                        name="horaInicial"
                                        label={"hora Inicial"}
                                        //InputLabelProps={{ shrink: true }}
                                        slotProps={{
                                          inputLabel: { shrink: true },
                                        }}
                                        type="time"
                                        size="small"
                                        value={
                                          listTags[index]?.configuracoes
                                            ?.horarioPersonalizado?.horaInicial
                                            ? listTags[index]?.configuracoes
                                                ?.horarioPersonalizado
                                                ?.horaInicial
                                            : null
                                        }
                                        onChange={(event) => {
                                          handleListItensChange(index, event);
                                        }}
                                        sx={{
                                          ...styles.confItensTextField,
                                          input: {
                                            colorScheme:
                                              theme.palette.mode === "light"
                                                ? "light"
                                                : "dark",
                                          },
                                        }}
                                      />
                                      <TextField
                                        name="horaFinal"
                                        label={"Hora Final"}
                                        //InputLabelProps={{ shrink: true }}
                                        slotProps={{
                                          inputLabel: { shrink: true },
                                        }}
                                        type="time"
                                        size="small"
                                        value={
                                          listTags[index]?.configuracoes
                                            ?.horarioPersonalizado?.horaFinal
                                            ? listTags[index]?.configuracoes
                                                ?.horarioPersonalizado
                                                ?.horaFinal
                                            : null
                                        }
                                        onChange={(event) => {
                                          handleListItensChange(index, event);
                                        }}
                                        sx={{
                                          ...styles.confItensTextField,
                                          input: {
                                            colorScheme:
                                              theme.palette.mode === "light"
                                                ? "light"
                                                : "dark",
                                          },
                                        }}
                                      />
                                    </>
                                  )}
                                {text?.key === "variacaoAbrupta" &&
                                  listTags[index]?.configuracoes
                                    ?.variacaoAbrupta?.status && (
                                    <TextField
                                      label={`variacao`}
                                      type="number"
                                      size="small"
                                      //InputLabelProps={{ shrink: true }}
                                      slotProps={{
                                        inputLabel: { shrink: true },
                                        input: {
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              %
                                            </InputAdornment>
                                          ),
                                        },
                                      }}
                                      value={
                                        listTags[index]?.configuracoes
                                          ?.variacaoAbrupta?.variacao
                                          ? listTags[index]?.configuracoes
                                              ?.variacaoAbrupta?.variacao
                                          : null
                                      }
                                      onChange={(event) => {
                                        handleListItensChange(index, event);
                                      }}
                                      sx={styles.confItensTextField}
                                    />
                                  )}
                              </Stack>
                            </Box>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </FormGroup>
                  </TabPanel>
                ))}
              </TabContext>
            </DialogContent>{" "}
            {/* <DialogTitle sx={{ paddingTop: 0 }} color="primary">
              Ocultar gráficos
            </DialogTitle> */}
            {/* <DialogContent sx={styles.dialogContent}>
              <FormGroup sx={{ width: "fit-content" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ocultarGraficos}
                      size="small"
                      name="chart"
                      onChange={(event) => {
                        handleOcultarGraficos(event);
                      }}
                    />
                  }
                  label="Ocultar gráficos"
                />
              </FormGroup>
            </DialogContent> */}
            <DialogActions>
              <Button color="secondary" onClick={onCloseModal}>
                Cancelar
              </Button>
              <Button onClick={onButtonSave}>Salvar</Button>
            </DialogActions>
          </Paper>
        </Box>
      </Modal>
      <AlertModal
        openModal={openConfirmDialog}
        setOpenModal={setOpenConfirmDialog}
        confirmar={disableButton}
        handleButton={handleApiPutHosts}
        severity={"warning"}
        singleButton={false}
      />
      <SnackBar snackbar={snackbar} setSnackbar={setSnackbar} />
    </>
  );
}
