import { Add, Edit, Save } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useEffect, useRef } from "react";

export default function CustomToolbar(props) {
  const {
    hosts,
    setEditMode,
    editMode,
    setCellModesModel,
    setHostsEdit = () => {},
    updateHosts,
    setKeyTable = () => {},
    setCellSelected = () => {},
  } = props;
  const id = JSON.stringify(Math.random());
  const apiRef = useGridApiContext();
  const toolbarRef = useRef(null);
  const addRow = () => {
    return {
      id,
      nome: "",
      ip: "",
      portaSnmp: "",
      userSsh: "",
      senhaSsh: "",
      portaSsh: "",
      userTelnet: "",
      portaTelnet: "",
      senhaTelnet: "",
      tipoSnmp: "",
      comunidade: "",
      status: 0,
      coletor: "",
      fabricante: "",
      funcao: "",
      modelo: "",
      newHost: true,
    };
  };

  const handleEditClick = () => {
    // const rowModesModel = hosts.reduce((acc, row) => {
    //   acc[row.id] = { mode: GridCellModes.Edit }; // Ajusta 'edit' diretamente como string
    //   return acc;
    // }, {});
    // setRowModesModel(rowModesModel);
    setHostsEdit(hosts);
    setEditMode(true);
  };

  const handleAddClick = () => {
    setCellSelected(null);
    let newLine = addRow();
    //apiRef.current.updateRows([newLine]);
    if (!editMode) {
      setEditMode(true);
      setHostsEdit([newLine, ...hosts]);
      return;
    }
    setHostsEdit((data) => [newLine, ...data]);
    // setRowModesModel((data) => {
    //   data[newLine.id] = { mode: GridCellModes.Edit };
    //   return { ...data };
    // });
  };

  function setViewModeCell() {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: "view" },
              }),
              {}
            ),
          }),
          {}
        ),
      };
    });
  }

  const handleSaveClick = () => {
    setCellSelected(null);
    setViewModeCell();
    updateHosts();
    // const rowIds = apiRef.current.getAllRowIds();
    // const rows = rowIds.map((a) => {
    //   return apiRef.current.unstable_getRowWithUpdatedValuesFromRowEditing(a);
    // });
    // setRowModesModel((rowModes) => {
    //   Object.keys(rowModes).map((key) => {
    //     rowModes[key].mode = GridCellModes.View;
    //   });
    //   return { ...rowModes };
    // });
    // setTimeout(() => {
    //   let removeLines = Object.keys(rowModesModel).filter((key) => key < 1);
    //   removeLines.map((a) => {
    //     if (apiRef.current.getRow(a))
    //       apiRef.current.updateRows([{ id: a, _action: "delete" }]);
    //   });
    //   setRowModesModel((rowModes) => {
    //     removeLines.map((rowId) => {
    //       delete rowModes[rowId];
    //     });
    //     return { ...rowModes };
    //   });
    // }, [100]);
  };

  const handleCancelClick = () => {
    setCellSelected(null);
    //setValidateData(true);
    setViewModeCell();
    // let ids = apiRef.current.getAllRowIds();
    // let removeLines = ids.filter((a) => a < 1);
    // removeLines.map((a) => {
    //   if (apiRef.current.getRow(a))
    //     apiRef.current.updateRows([{ id: a, _action: "delete" }]);
    // });
    // const rowModes = hosts.reduce((acc, row) => {
    //   acc[row.id] = { mode: GridCellModes.View, ignoreModifications: true }; // Ajusta 'edit' diretamente como string
    //   return acc;
    // }, {});
    // setRowModesModel(rowModes);
    setKeyTable((data) => (data == 1 ? 0 : 1));
    setHostsEdit([]);
    setEditMode(false);
  };
  useEffect(() => {
    // Adiciona o listener para detectar cliques fora do DataGrid
    document.addEventListener("mousedown", handleClickInside);
    // Remove o listener quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickInside);
    };
  }, []);
  const handleClickInside = (event) => {
    // Verifica se o clique foi fora do DataGrid
    if (toolbarRef.current && toolbarRef.current.contains(event.target)) {
      setCellSelected(null);
      // Aqui você pode adicionar sua lógica para quando o clique for fora do DataGrid
    }
  };

  return (
    <GridToolbarContainer>
      <Stack
        ref={toolbarRef}
        sx={{ margin: 1, width: "100%" }}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </Stack>
        <Stack direction={"row"} justifyContent={"end"} gap={1}>
          {" "}
          <Button
            variant="text"
            sx={{ fontSize: "0.8125rem" }}
            startIcon={<Add />}
            onClick={handleAddClick}
          >
            Adicionar linha
          </Button>
          {!editMode ? (
            <Button
              variant="text"
              onClick={handleEditClick}
              startIcon={<Edit />}
              disabled={hosts.length == 0}
              sx={{ fontSize: "0.8125rem" }}
            >
              Editar
            </Button>
          ) : (
            <>
              <Button
                variant="text"
                sx={{ fontSize: "0.8125rem" }}
                startIcon={<Save />}
                onClick={handleSaveClick}
              >
                Salvar
              </Button>
              <Button
                variant="text"
                sx={{ fontSize: "0.8125rem" }}
                color="secondary"
                onClick={handleCancelClick}
              >
                Cancelar
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
}
