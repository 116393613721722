import {
  CheckCircle,
  Delete,
  DownloadOutlined,
  Edit,
  Error,
  Pending,
  PermDataSettingOutlined,
  ReplayOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
  Tooltip,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { renderToStaticMarkup } from "react-dom/server";
import api from "../../../../services/api";
import TabelaRelatorio from "./tabelaRelatorios";
import ModalEditaItens from "./modalEditaItens";
import ModalConfigItens from "./modalConfigItens";
import { PageTemplateEasyReport } from "../historicoRelatorio/generateReport";
import { HeaderBar } from "./headerBar";
import ChartBoolean from "./chartsTypes/booleanChart";
import TrafficChart from "./chartsTypes/traficChart";
import ChartPie from "./chartsTypes/pieChart";
import NumberChart from "./chartsTypes/numberChart";
import AlertModal from "../../../../componentes/alertModal";
import SnackBar from "../../../../componentes/snackbar";
import ModalConfigFlow from "./modalConfigFlow";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

const stylesHistoric = {
  BoxContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "40px",
    gap: "20px",
    width: "100%",
  },
  paper: {
    //width: "98.5%",
    minWidth: "70vw",
    padding: "35px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 3,
  },
  paperContainer: {
    padding: "20px",
    //minWidth: "70vw",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: 3,
  },
  stackHeaderBar: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "-20px",
    paddingBottom: "24px",
  },
  TypographyCampo: { fontSize: 15 },
  chipItem: {
    bgcolor: "chip.arquive",
    color: "white",
    minWidth: "100px",
    maxWidth: "250px",
  },
};
export default function HistoricoRelatorio(props) {
  const { relatorioId } = useParams();
  const [editarItens, setEditarItens] = useState(false);
  const [selectedRowHosts, setSelectedRowHosts] = useState("");
  const [rows, setRows] = useState({
    HostEasyReport: [],
    HistoricoEasyReport: [],
  });
  const [tipo, setTipo] = useState(0);
  const [hosts, setHosts] = useState([]);
  const [historico, setHistorico] = useState([]);
  const [loadingTableConfig, setLoadingTableConfig] = useState(false);
  const [loadingTableReport, setLoadingTableReport] = useState(false);
  const [itensCadastrados, setItensCadastrados] = useState([]);
  const [updateRow, setUpdateRow] = useState("");
  const [openModalConfig, setOpenModalConfig] = useState(false);
  const [relatorio, setRelatorio] = useState([]);
  const [disabledConfigItens, setDisabledConfigItens] = useState(true);
  const [isLoadingDownLoad, setIsLoadingDownLoad] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [disableButtonDelete, setDissableButtonDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    conteudo: false,
    hostId: false,
  });
  const open = Boolean(anchorEl);
  const file = useMemo(() => relatorio, [relatorio]);

  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickButtonConfig = () => {
    setOpenModalConfig(true);
  };

  useEffect(() => {
    handleApiGetRelatorios();
  }, []);

  useEffect(() => {
    if (file.id) {
      // const downloadWindow = window.open("", "_blank");
      // downloadWindow.document.write("Baixando...");
      handleDownloadPDF();
      // downloadWindow.document.close();
    }
  }, [file]);

  async function handleGenerateGraphPNG(dataIn, tipo) {
    let graphOut = [];
    await Promise.all(
      dataIn.map(async (a) => {
        if (tipo === 0) {
          if (a.tag === "boolean") {
            let arraySend = [];
            let staticHtmlBool = renderToStaticMarkup(
              <ChartBoolean data={a.history ? a.history : []} />
            );
            arraySend.push({
              data: btoa(unescape(encodeURIComponent(staticHtmlBool))),
              width: 790,
              height: 200,
            });

            if (a.availability) {
              let staticHtmlPie = renderToStaticMarkup(
                <ChartPie data={a.availability.availability} />
              );
              arraySend.push({
                data: btoa(unescape(encodeURIComponent(staticHtmlPie))),
                width: 600,
                height: 300,
              });
            }
            let graphcs = await handleApiGetBase64(arraySend);
            graphOut.push({
              host: a.hostName,
              item: a.itemName,
              graphcs: [{ bool: graphcs[0], pie: graphcs[1] }],
            });
          } else if (a.tag === "traffic") {
            let staticHtml = renderToStaticMarkup(
              <TrafficChart
                data={a.history ? a.history : []}
                c95th={a.c95th}
                limitTraffic={a.limitTraffic}
              />
            );
            let graphcs = await handleApiGetBase64([
              {
                data: btoa(unescape(encodeURIComponent(staticHtml))),
                width: 790,
                height: 200,
              },
            ]);
            graphOut.push({
              host: a.hostName,
              item: a.itemName,
              graphcs: graphcs[0],
            });
          } else if (a.tag == "number") {
            let staticHtml = renderToStaticMarkup(
              <NumberChart data={a.history ? a.history : []} unit={a.unit} />
            );
            let graphcs = await handleApiGetBase64([
              {
                data: btoa(unescape(encodeURIComponent(staticHtml))),
                width: 790,
                height: 200,
              },
            ]);
            graphOut.push({
              host: a.hostName,
              item: a.itemName,
              graphcs: graphcs[0],
            });
          }
        } else if (tipo === 1) {
          let percentilClients = [];
          await Promise.all(
            a.analysis.map(
              async ({ history, type, th95, trafficFull, limitTraffic }) => {
                let staticHtmlTraffic = renderToStaticMarkup(
                  <TrafficChart
                    data={history ? history : []}
                    c95th={th95}
                    limitTraffic={limitTraffic}
                  />
                );
                percentilClients.push({
                  name: type,
                  value: trafficFull,
                  status: true,
                });
                let graphcs = await handleApiGetBase64([
                  {
                    data: btoa(unescape(encodeURIComponent(staticHtmlTraffic))),
                    width: 790,
                    height: 200,
                  },
                ]);

                graphOut.push({
                  client: a.client,
                  type,
                  graphcs: graphcs[0],
                });
              }
            )
          );
          let staticHtmlPie = renderToStaticMarkup(
            <ChartPie data={percentilClients} tipo={tipo} />
          );

          let graphcs = await handleApiGetBase64([
            {
              data: btoa(unescape(encodeURIComponent(staticHtmlPie))),
              width: 600,
              height: 300,
            },
          ]);

          graphOut.push({
            client: a.client,
            type: "all",
            graphcs: graphcs[0],
          });
        }
      })
    );

    return graphOut;
  }
  function agrupaItensDeConteudo(graphcs) {
    const itens = graphcs.reduce((accumulator, { graphcs, item, host }) => {
      if (accumulator.some((a) => a.item === item)) {
        accumulator.map((a) => {
          if (a.item === item) {
            a.graphcs.push({ host, graphcs });
          }
        });
      } else {
        accumulator.push({ graphcs: [{ host, graphcs }], item });
      }

      return accumulator;
    }, []);
    return itens;
  }

  async function handleDownloadPDF() {
    try {
      let graphcs = null;
      let hideCharts = true;

      hideCharts = relatorio?.HistoricoEasyReport?.conteudo[0]?.hideCharts;
      if (!hideCharts) {
        graphcs = await handleGenerateGraphPNG(
          relatorio.HistoricoEasyReport.conteudo,
          relatorio.tipo
        );
        graphcs = tipo === 0 ? agrupaItensDeConteudo(graphcs) : graphcs;
      }
      const blob = await pdf(
        <PageTemplateEasyReport relatorio={relatorio} graphcs={graphcs} />
      ).toBlob();
      setHistorico((prevHistorico) =>
        prevHistorico.map((historico) => {
          return { ...historico, download: { status: false } };
        })
      );
      saveAs(
        blob,
        `mwRelatorio - ${relatorio.titulo} - etapa ${relatorio.HistoricoEasyReport.etapa}.pdf`
      );
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível gerar o relatório",
        severity: "error",
      });
      setHistorico((prevHistorico) =>
        prevHistorico.map((historico) => {
          return { ...historico, download: { status: false } };
        })
      );
    }
  }

  useEffect(() => {
    if (updateRow) {
      const novoArray = hosts.map((obj) => {
        if (obj.id === updateRow.id) {
          return { ...updateRow };
        } else {
          return obj;
        }
      });
      setHosts(novoArray);
    }
  }, [updateRow]);

  async function handleApiGetBase64(htmlBase64) {
    try {
      const response = await api.post("/utils/htmlToImageBase64", {
        htmlBase64,
      });

      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
  function itensAgrupados(hosts) {
    const uniqueKeys = new Set();

    return hosts
      .flatMap((host) => host?.itens || [])
      .filter(({ key_ }) => !uniqueKeys.has(key_) && uniqueKeys.add(key_));
  }

  async function handleApiGetRelatorios() {
    try {
      setLoadingTableConfig(true);
      setLoadingTableReport(true);
      const response = await api.post("/easyReport/relatorio/find", {
        id: relatorioId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          setRows(response.data);
          setTipo(response.data.tipo);
          setColumnVisibilityModel(
            response.data?.tipo === 0
              ? { id: false, hostId: false, conteudo: false }
              : response.data?.HostEasyReport[0]?.itens?.tarifacaoExcedente
                  ?.status
              ? {
                  FNA: false,
                  CDN: false,
                  GGC: false,
                  "IX/PTT": false,
                  "TRANS/IP": false,
                  "OCA|NETFLIX": false,
                  id: false,
                }
              : {
                  id: false,
                  tarifacao: false,
                  limite: false,
                  tarifacaoExcedente: false,
                }
          );
          setHosts(response.data.HostEasyReport);
          setHistorico(
            response.data.HistoricoEasyReport.map((historico) => {
              return { ...historico, download: { status: false } };
            })
          );

          setItensCadastrados(itensAgrupados(response.data.HostEasyReport));
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setDisabledConfigItens(false);
      setLoadingTableConfig(false);
      setLoadingTableReport(false);
    }
  }
  async function handleApiDeleteRelatorio(params) {
    try {
      setDissableButtonDelete(true);
      const response = await api.post("/easyReport/relatorio/delete", {
        id: relatorioId,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          navigate("/easyReport/agendar", { state: { infoModal: true } });
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setDissableButtonDelete(false);
    }
  }

  const columnsConfigFlow = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "nome",
      headerName: "Clientes",
      flex: 3,
    },
    {
      field: "itens",
      headerName: "Versão IP",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>
              {Array.isArray(params.value.inet) && params.value.inet.length > 0
                ? params.value.inet.join(", ")
                : ""}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "tarifacao",
      headerName: "Valor por Mbps (R$)",
      width: 180,
      renderCell: (params) => {
        return (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>
              {params.row.itens?.tarifacaoExcedente?.valor.toLocaleString(
                "pt-br",
                {
                  minimumFractionDigits: 2,
                }
              )}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "tarifacaoExcedente",
      headerName: "Valor do Excedente (R$)",
      width: 180,
      renderCell: (params) => {
        return (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>
              {params.row.itens?.tarifacaoExcedente?.valorExcedente.toLocaleString(
                "pt-br",
                {
                  minimumFractionDigits: 2,
                }
              )}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "limite",
      headerName: "Limite (Mbps)",
      width: 180,
      renderCell: (params) => {
        return (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>
              {params.row.itens?.tarifacaoExcedente?.limite.toLocaleString(
                "pt-br",
                {
                  minimumFractionDigits: 2,
                }
              )}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "FNA",
      headerName: "FNA (R$)",
      width: 180,
      renderCell: (params) => {
        return (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>
              {params.row.itens?.tarifacao?.FNA?.valor.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "GGC",
      headerName: "GGC (R$)",
      width: 180,
      renderCell: (params) => {
        return (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>
              {params.row.itens?.tarifacao?.GGC?.valor.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "IX/PTT",
      headerName: "IX/PTT (R$)",
      width: 180,
      renderCell: (params) => {
        return (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>
              {params.row.itens?.tarifacao["IX/PTT"]?.valor.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 }
              )}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "TRANS/IP",
      headerName: "TRANS/IP (R$)",
      width: 180,
      renderCell: (params) => {
        return (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>
              {params.row.itens?.tarifacao["TRANS/IP"]?.valor.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 }
              )}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "OCA|NETFLIX",
      headerName: "OCA|NETFLIX (R$)",
      width: 180,
      renderCell: (params) => {
        return (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>
              {params.row.itens?.tarifacao["OCA|NETFLIX"]?.valor.toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 }
              )}
            </Typography>
          </Stack>
        );
      },
    },
  ];
  const columnsConfig = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "nome",
      headerName: "Hosts",
      width: 180,
    },
    { field: "hostId", headerName: "Host Id", width: 180 },
    {
      field: "itens",
      headerName: "Itens",
      flex: 3,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            {params.value.length > 0
              ? params.value?.map((item, index) => (
                  <Chip
                    key={index}
                    label={item.name || item.item}
                    // onDelete={editarItens ? handleDelete : false}
                    sx={stylesHistoric.chipItem}
                  />
                ))
              : null}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 60,

      renderCell: (params) => {
        const onClick = (e) => {
          //e.stopPropagation();
          // don't select this row after clicking
          const thisRow = params.row;

          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== "__check__" && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          //   );

          hanldeUpDateSelectRow(thisRow);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setEditarItens(true);
          setSelectedRowHosts(thisRow);
        }
        //
        return (
          <Box key={params.id}>
            {tipo === 0 ? (
              <Tooltip title={"Editar"} id="basic-button" onClick={onClick}>
                <IconButton size="small">
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ];
  function renderCellDateColumn(params) {
    return (
      <>
        {params.value && (
          <Typography variant="subtitle1" sx={stylesHistoric.TypographyCampo}>
            {dayjs(new Date(params.value))
              .add(3, "hours")
              .toDate()
              .toLocaleDateString()}
          </Typography>
        )}
      </>
    );
  }
  const columnsScheduled = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "titulo",
      headerName: "Titulo do relatorio",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="subtitle1" sx={stylesHistoric.TypographyCampo}>
          {rows.titulo}
        </Typography>
      ),
    },

    {
      field: "dataInicialColeta",
      headerName: "Data Inicial de Coleta ",
      // type: "date",
      width: 170,
      renderCell: renderCellDateColumn,
    },
    {
      field: "dataFinalColeta",
      headerName: "Data Final de Coleta",
      //type: "date",
      width: 170,
      renderCell: renderCellDateColumn,
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: (params) => (
        <Box key={params.id}>
          {params.value === 1 ? (
            <Tooltip title={"Relatório gerado com sucesso"} id="basic-button">
              <CheckCircle color="success" />
            </Tooltip>
          ) : params.value === 2 ? (
            <Tooltip
              title={"Erro ao gerar relatório: " + params.row?.erro}
              id="basic-button"
            >
              <Error color="error" />
            </Tooltip>
          ) : (
            <Pending color="primary" />
          )}
        </Box>
      ),
    },
    {
      field: "etapa",
      headerName: "Etapa",
      width: 80,
      renderCell: (params) => (
        <>
          {params.value && (
            <Typography variant="subtitle1" sx={stylesHistoric.TypographyCampo}>
              {params.value + "/" + historico?.length}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "conteudo",
      headerName: "Conteudo",
      width: 80,
      hide: true,
    },
    {
      field: "download",
      headerName: "",
      sortable: false,
      width: 60,

      renderCell: (params) => {
        const onClickDownload = (e) => {
          e.stopPropagation();
          // don't select this row after clicking
          const api = params.api;
          const thisRow = params.row;

          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== "__check__" && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          //   );
          setHistorico((prevHistorico) =>
            prevHistorico.map((historico) => {
              if (historico.id === params.row.id)
                return { ...historico, download: { status: true } };
              return historico;
            })
          );
          setRelatorio({
            ...rows,
            HistoricoEasyReport: historico.find(({ id }) => id === thisRow.id),
          });
        };
        //

        return (
          <Box key={params.id}>
            {params.value.status ? (
              <Box
                sx={{ display: "flex", alignItems: "center", padding: "5px" }}
              >
                <CircularProgress size={20} />
              </Box>
            ) : params.row.status === 1 ? (
              <Tooltip
                title={"Fazer Download"}
                id="basic-button"
                onClick={onClickDownload}
              >
                <IconButton size="small">
                  <DownloadOutlined color="primary" />
                </IconButton>
              </Tooltip>
            ) : params.row.status === 2 ? (
              <Tooltip
                title={"Tentar Reload"}
                id="basic-button"
                onClick={onClickDownload}
              >
                <IconButton size="small">
                  <ReplayOutlined color="secondary" />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton size="small">
                <Pending color="primary" />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  const filterGenerated = (reports) => {
    return reports?.filter((report) => report.status === 1) || [];
  };

  const filterSheScheduled = (reports) => {
    return reports?.filter((report) => report.status === 0) || [];
  };

  return (
    <>
      <Box direction="row" sx={stylesHistoric.BoxContainer}>
        <TabelaRelatorio
          rows={hosts}
          columnVisibilityModel={columnVisibilityModel}
          columns={tipo === 0 ? columnsConfig : columnsConfigFlow}
          title={
            tipo === 0
              ? rows.titulo + " - Configurações de Relatório"
              : rows.titulo + " - Tarifacao de Cada Classe em R$ (real)"
          }
          loading={loadingTableConfig}
        >
          <Stack sx={stylesHistoric.stackHeaderBar} direction="row">
            <HeaderBar
              tipo={rows.tipo}
              dataInicial={rows.dataInicial}
              dataFinal={rows.dataFinal}
              periodoColeta={rows.periodoColeta}
              //inet={rows.HostEasyReport[0]?.itens?.inet?.join(", ")}
            />
            <Button
              id={"basic-button"}
              color="primary"
              variant="contained"
              startIcon={<PermDataSettingOutlined />}
              //
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              disabled={disabledConfigItens}
            >
              {tipo === 0 ? "Configurações de itens" : "Configurações"}
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  onClickButtonConfig();
                  handleClose();
                }}
                //  disabled
              >
                <ListItemIcon>
                  <Edit fontSize="small" color="primary" />
                </ListItemIcon>
                <ListItemText>Editar</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenDeleteDialog(true);
                  // handleClose();
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="small" color="secondary" />
                </ListItemIcon>
                <ListItemText>Deletar</ListItemText>
              </MenuItem>
            </Menu>
          </Stack>
        </TabelaRelatorio>
        <TabelaRelatorio
          rows={filterGenerated(historico)}
          columns={columnsScheduled}
          title={rows.titulo + " - Relatórios Gerados"}
          loading={loadingTableReport}
        />
        <TabelaRelatorio
          defaultExpanded={false}
          rows={filterSheScheduled(historico)}
          columns={columnsScheduled}
          title={rows.titulo + " - Relatório Agendados"}
          columnVisibilityModel={{
            status: false,
            download: false,
            id: false,
            conteudo: false,
          }}
          loading={loadingTableReport}
        />
      </Box>
      <ModalEditaItens
        openEditModal={editarItens}
        setOpenEditModal={setEditarItens}
        selectedRow={selectedRowHosts}
        setSnackbar={setSnackbar}
        itensCadastrados={itensCadastrados}
        setUpdateRow={setUpdateRow}
      />
      {tipo === 0 ? (
        <ModalConfigItens
          openModalConfig={openModalConfig}
          setOpenModalConfig={setOpenModalConfig}
          hosts={hosts}
        />
      ) : tipo === 1 ? (
        <ModalConfigFlow
          openModalConfig={openModalConfig}
          setOpenModalConfig={setOpenModalConfig}
          hosts={hosts}
        />
      ) : null}

      <AlertModal
        openModal={openDeleteDialog}
        setOpenModal={setOpenDeleteDialog}
        confirmar={disableButtonDelete}
        handleButton={handleApiDeleteRelatorio}
        severity={"error"}
        singleButton={false}
      />
      <SnackBar snackbar={snackbar} setSnackbar={setSnackbar} />
    </>
  );
}
