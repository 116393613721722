import { useState } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  IconButton,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ExpandMore, UnarchiveOutlined } from "@mui/icons-material";
import api from "../../../services/api";
import { ptBR } from "@mui/x-data-grid/locales";

const stylesImportCsv = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
    width: "100%",
  },
  paper: {
    width: "100%",
    minWidth: "900px",
    padding: "20px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 3,
    elevation: 3,
  },
  accordion: {
    width: "100%",
    // width: "84vw",
    // minWidth: "900px",
    borderRadius: 3,
  },
  button: { height: "40px", minWidth: "120px", m: 1 },
  filter: { width: "180px" },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
  porta: { width: "105px" },
  textField: { width: "230px" },
  titles: { fontWeight: 500, fontSize: "20px" },
};

export default function HostsArquivados(props) {
  const { setSnackbar, loading, hosts, setHosts } = props;
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    senhaTelnet: false,
    portaTelnet: false,
    userTelnet: false,
    tipoSnmp: false,
    portaSnmp: false,
    comunidade: false,
    senhaSsh: false,
    userSsh: false,
    portaSsh: false,
  });

  async function handleApiUpdateHosts(hosts) {
    try {
      setLoadingUpdate(true);
      const response = await api.put("/easyAdd/hosts/update", {
        hosts,
      });
      if (response.data) {
        const updateHosts = response?.data;

        setHosts((prevHosts) => {
          const hostMap = new Map(prevHosts.map((host) => [host.id, host]));
          updateHosts?.forEach((newHost) => {
            hostMap.set(newHost.id, newHost);
          });
          return Array.from(hostMap.values());
        });

        setSnackbar({
          children: "Hosts salvos com sucesso!",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingUpdate(false);
    }
  }

  const columns = [
    { field: "id", headerName: "ID", width: 180, hide: true },
    {
      field: "nome",
      headerName: "NOME",
      flex: 1,
      //width: 175,
    },
    {
      field: "ip",
      headerName: "IP",
      width: 150,
    },
    {
      field: "portaSnmp",
      headerName: "PORTA SNMP",
      width: 80,
    },
    {
      field: "tipoSnmp",
      headerName: "TIPO SNMP",
      width: 85,
    },
    {
      field: "comunidade",
      headerName: "COMUNIDADE",
      width: 150,
    },
    {
      field: "fabricante",
      headerName: "FABRICANTE",
      editable: true,
      width: 150,
    },
    {
      field: "funcao",
      headerName: "FUNÇÃO",
      width: 150,
    },
    {
      field: "modelo",
      headerName: "MODELO",
      width: 150,
      hide: true,
    },
    {
      field: "coletor",
      headerName: "COLETOR",
      width: 150,
    },
    {
      field: "tipoSnmp",
      headerName: "SNMP",
      width: 70,
      type: "",
    },
    {
      field: "userSsh",
      headerName: "USER SSH",
      width: 100,
    },
    {
      field: "portaSsh",
      headerName: "PORTA SSH",
      width: 100,
    },
    {
      field: "senhaSsh",
      headerName: "SENHA SSH",
      width: 100,
    },
    {
      field: "userTelnet",
      headerName: "USER TELNET",
      width: 100,
    },
    {
      field: "portaTelnet",
      headerName: "PORTA TELNET",
      width: 110,
    },
    {
      field: "senhaTelnet",
      headerName: "SENHA TELNET",
      width: 110,
    },
    {
      field: "actions",
      headerName: "OPÇÃO",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        const handleArquive = (e) => {
          handleApiUpdateHosts([
            {
              ...hosts.find((a) => a.id === params.id),
              status: 0,
            },
          ]);
        };

        return (
          <Tooltip title="Desarquivar">
            <IconButton disabled={loadingUpdate} onClick={handleArquive}>
              <UnarchiveOutlined fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={stylesImportCsv.container}>
        <Accordion
          sx={stylesImportCsv.accordion}
          square
          elevation={3}
          defaultExpanded={false}
        >
          <AccordionSummary
            aria-controls="previous-regsiter-content"
            id="previous-regsiter-header"
            expandIcon={<ExpandMore />}
          >
            {" "}
            <Typography color={"primary"} sx={stylesImportCsv.titles}>
              Hosts Arquivados
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              sx={{
                height:
                  hosts.length > 19
                    ? "800px"
                    : hosts.length == 0
                    ? "250px"
                    : hosts.length * 36 + 94 + "px",
              }}
            >
              <DataGrid
                density="compact"
                loading={loading}
                editMode="row"
                sx={{
                  fontSize: 13,
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  height: "100%",

                  ".MuiDataGrid-cell": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={hosts}
                columns={columns}
                pageSizeOptions={[25, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
                disableRowSelectionOnClick={true}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
