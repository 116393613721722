import { Box, Stack } from "@mui/material";
import { GridPagination } from "@mui/x-data-grid";

export const CustomPagination = (props) => {
  const { setCellSelected = () => {} } = props;
  return (
    <Box sx={{ width: "100%" }} onClick={() => setCellSelected(null)}>
      <GridPagination {...props} />
    </Box>
  );
};
